import React from 'react';

export const Filter = ({ links = null, select }) => {
  return (
    <div className="filter">
      {links && <ul className="filter__links">{links}</ul>}

      {select ? <div className="filter__select">{select}</div> : null}
    </div>
  );
};
