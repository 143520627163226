import React from 'react';

export const ARROW_LEFT_SLIDER = (
  <svg
    width="13"
    height="20"
    viewBox="0 0 13 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.1387 1.625L2.76478 9.99889L11.1387 18.3728"
      stroke="black"
      strokeWidth="2.75862"
    />
  </svg>
);

export const ARROW_LEFT_SLIDER_TEXT = /* html */ `
  <svg
    width="13"
    height="20"
    view-box="0 0 13 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.1387 1.625L2.76478 9.99889L11.1387 18.3728"
      stroke="black"
      stroke-width="2.75862"
    />
  </svg>
`;
