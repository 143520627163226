import classnames from 'classnames';
import React from 'react';

import { useData } from '../../helpers/data.js';
import { isImage } from '../../helpers/image';
import { formatFieldName } from '../../helpers/string';

import { generateCardDetails } from '../card-details';
import { Image } from '../image';
import { Link } from '../link';
import { Tag } from '../tag';

/**
 * @typedef {'third'|'half'|'full'} CardLayout
 */

/**
 * @enum {CardLayout}
 * @type {Object.<string, CardLayout>}
 */
export const CARD_LAYOUT = {
  THIRD: 'third',
  HALF: 'half',
  FULL: 'full',
};

/**
 * @param {Object} props
 * @param {CardLayout} [props.layout=CardLayout.THIRD]
 * @param {any|null} [props.image]
 * @param {any|null} [props.tag]
 * @param {any|null} [props.title]
 * @param {any|null} [props.details]
 * @param {any|null} [props.link]
 * @param {boolean} [props.lazyImage=false]
 * @param {boolean} [props.reverse=false]
 */
export const Card = ({
  layout = CARD_LAYOUT.THIRD,
  image = null,
  tag = null,
  title = null,
  details = null,
  link = null,
  lazyImage = true,
  reverse = false,
}) => {
  return (
    <div
      className={classnames('card', `card--layout-${layout}`, {
        'card--reverse': reverse,
      })}
    >
      <div className="card__image">
        {isImage(image) ? (
          <Image
            image={image}
            sizes={
              layout === CARD_LAYOUT.FULL
                ? '(min-width: 1141px) 712px, (min-width: 769px) 70vw, 100vw'
                : layout === CARD_LAYOUT.HALF
                  ? '(min-width: 1141px) 226px, (min-width: 769px) 50vw, 100vw'
                  : '(min-width: 1141px) 354px, (min-width: 769px) 50vw, 100vw'
            }
            lazy={lazyImage}
          />
        ) : null}
      </div>

      <div className="card__content">
        {tag ? <Tag>{tag}</Tag> : null}

        {title ? <h3 className="card__title">{title}</h3> : null}

        {details ? <div className="card__details">{details}</div> : null}

        {link ? <div className="card__link">{link}</div> : null}
      </div>
    </div>
  );
};

/**
 * @param {Object} props
 * @param {any} props.blok
 * @param {any} [props.tag]
 * @param {any} [props.link]
 * @param {boolean} props.isFlex
 * @param {boolean} props.displayDates
 * @param {boolean} props.lazyImage
 * @param {import('./index.js').CardLayout} [props.layout]
 */
Card.Preview = function CardPreview({
  blok,
  tag,
  link,
  displayDates = true,
  lazyImage = true,
  ...rest
}) {
  const { locale } = useData();

  return (
    <Card
      image={
        isImage(blok.content.previewImage)
          ? blok.content.previewImage
          : blok.content.image
      }
      tag={tag}
      title={
        <Link
          page={blok}
          dangerouslySetInnerHTML={{
            __html: formatFieldName(blok.content),
          }}
        />
      }
      details={generateCardDetails({
        startAt: blok.content.startAt,
        endAt: blok.content.endAt,
        location: blok.content.location,
        locale,
        displayDates,
      })}
      link={link}
      lazyImage={lazyImage}
      {...rest}
    />
  );
};

/**
 * @param {Object} props
 * @param {any} props.exhibition
 * @param {any} [props.tag]
 * @param {any} [props.link]
 * @param {import('./index.js').CardLayout} [props.layout]
 */
// Card.Exhibition = ({ exhibition, tag, link, ...rest }) => {
//   const { locale } = useData();

//   return (
//     <Card
//       image={
//         isImage(exhibition.content.previewImage)
//           ? exhibition.content.previewImage
//           : exhibition.content.image
//       }
//       tag={tag}
//       title={
//         <Link
//           page={exhibition}
//           dangerouslySetInnerHTML={{
//             __html: formatFieldName(exhibition.content),
//           }}
//         />
//       }
//       details={generateCardDetails({
//         startAt: exhibition.content.startAt,
//         endAt: exhibition.content.endAt,
//         location: exhibition.content.location,
//         locale,
//       })}
//       link={link}
//       {...rest}
//     />
//   );
// };

/**
 * @param {Object} props
 * @param {any} props.news
 * @param {import('./index.js').CardLayout} [props.layout]
 */
Card.News = function CardNews({ news, ...rest }) {
  const { locale } = useData();

  return (
    <Card
      image={
        isImage(news.content.previewImage)
          ? news.content.previewImage
          : news.content.image
      }
      tag={news.content.category}
      title={
        <Link
          page={news}
          dangerouslySetInnerHTML={{
            __html: formatFieldName(news.content),
          }}
        />
      }
      details={generateCardDetails({
        startAt: news.content.startAt,
        endAt: news.content.endAt,
        location: news.content.location,
        locale,
      })}
      {...rest}
    />
  );
};

/**
 * @param {Object} props
 * @param {any} props.fair
 * @param {any} props.locale
 * @param {any} [props.tag]
 */
// Card.Fair = ({ fair, locale, ...rest }) => {
//   return (
//     <Card
//       image={
//         isImage(fair.content.previewImage)
//           ? fair.content.previewImage
//           : fair.content.image
//       }
//       title={
//         <Link
//           page={fair}
//           dangerouslySetInnerHTML={{
//             __html: formatFieldName(fair.content),
//           }}
//         />
//       }
//       details={generateCardDetails({
//         startAt: fair.content.startAt,
//         endAt: fair.content.endAt,
//         location: fair.content.location,
//         locale,
//       })}
//       {...rest}
//     />
//   );
// };

/**
 * @param {Object} props
 * @param {any} props.edition
 * @param {any} [props.tag]
 * @param {any} [props.link]
 */
Card.Edition = function CardEdition({ edition, ...rest }) {
  const { locale } = useData();

  return (
    <Card
      image={
        isImage(edition.content.previewImage)
          ? edition.content.previewImage
          : edition.content.image
      }
      tag={edition.content.category}
      title={
        <Link
          page={edition}
          dangerouslySetInnerHTML={{
            __html: formatFieldName(edition.content),
          }}
        />
      }
      details={generateCardDetails({
        startAt: edition.content.startAt,
        locale,
        displayDates: false,
      })}
      {...rest}
    />
  );
};
