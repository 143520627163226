import React from 'react';

export const CROSS = (
  <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="Frame 399">
    <path id="Line 19" d="M1.2924 12.6069L12.499 0.999023" stroke="black" stroke-width="1.5"/>
    <line id="Line 20" x1="1.53033" y1="1.15522" x2="12.844" y2="12.4689" stroke="black" stroke-width="1.5"/>
    </g>
  </svg>
);

export const CROSS_TEXT = `
  <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="Frame 399">
    <path id="Line 19" d="M1.2924 12.6069L12.499 0.999023" stroke="black" stroke-width="1.5"/>
    <line id="Line 20" x1="1.53033" y1="1.15522" x2="12.844" y2="12.4689" stroke="black" stroke-width="1.5"/>
    </g>
  </svg>
  `
