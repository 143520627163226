import classnames from 'classnames';
import React from 'react';

import { useData } from '../../helpers/data.js';
import { isImage } from '../../helpers/image';
import { render } from '../../helpers/storyblok/rich-text';
import { formatFieldName } from '../../helpers/string';

import { generateCardDetails } from '../card-details';
import { Image } from '../image';
import { Link } from '../link';
import { Tag } from '../tag';

export const WideCard = ({
  blok,
  item,
  content,
  displayDates = true,
  hideMobileLink = true,
  className = '',
}) => {
  const { locale } = useData();

  return (
    <div
      className={classnames('wide-card', className, {
        'wide-card--hide-mobile-link': hideMobileLink,
      })}
    >
      <div className="wide-card__image">
        <Image
          image={isImage(blok.previewImage) ? blok.previewImage : blok.image}
          sizes="(min-width: 769px) 225px, 50vw"
          lazy
        />
      </div>

      <div className="wide-card__content">
        {blok.supTitle ? (
          <Tag>{render(blok.supTitle) || blok.supTitle}</Tag>
        ) : null}

        <h3 className="wide-card__title">
          <Link page={item}>{formatFieldName(blok)}</Link>
        </h3>

        <div className="wide-card__content__text">
          {blok.startAt && blok.endAt && displayDates ? (
            generateCardDetails({
              startAt: blok.startAt,
              endAt: blok.endAt,
              location: blok.location,
              locale,
              displayDates,
            })
          ) : (
            <div className="wide-card__content__text__free">
              {render(blok.content)}
            </div>
          )}
        </div>
      </div>

      <div className="wide-card__link">{content.moreButtonText}</div>
    </div>
  );
};
