import React from 'react';

import { useData } from '../../helpers/data.js';

import { Footer } from '../footer';
import { Header } from '../header';
import { MobileMenu } from '../mobile-menu';

export const Layout = ({ children }) => {
  const { story, layouts } = useData();

  return (
    <>
      <Header blok={layouts.header} page={story} />
      <MobileMenu blok={layouts.header} page={story} />

      <main className="main">{children}</main>

      <Footer blok={layouts.footer} />
    </>
  );
};

export const LayoutRest = ({ children }) => {
  return <div className="layout__rest">{children}</div>;
};
