import {
  format,
  formatISO,
  isAfter,
  isSameDay,
  isSameMonth,
  isSameYear,
  isValid,
} from 'date-fns';
import { parse } from 'date-fns/esm';
import { enUS, fr } from 'date-fns/locale';

/**
 * @typedef {import('../config/index.js').Locale} Locale
 */

const DATE_FNS_LOCALES = {
  'en-us': enUS,
  fr: fr,
};

/**
 * @typedef {'upcoming'|'current'|'past'} Temporality
 */

/**
 * @enum {Temporality}
 * @type {Object.<string, Temporality>}
 */
export const TEMPOLARITY = {
  UPCOMING: 'upcoming',
  CURRENT: 'current',
  PAST: 'past',
};

export const TEMPORALITY_TOKEN = {
  [TEMPOLARITY.CURRENT]: 'Current',
  [TEMPOLARITY.UPCOMING]: 'Upcoming',
  [TEMPOLARITY.PAST]: 'Past',
};

export const isFutureDate = (date) => {
  return isAfter(date, new Date());
};

/**
 * @param {any} [startDate=null]
 * @param {any} [endDate=null]
 * @returns {Temporality}
 */
export const getTempolarity = (startDate = null, endDate = null) => {
  if (isFutureDate(parse(endDate, 'yyyy-MM-dd HH:mm', new Date()))) {
    if (isFutureDate(parse(startDate, 'yyyy-MM-dd HH:mm', new Date()))) {
      return TEMPOLARITY.UPCOMING;
    }

    return TEMPOLARITY.CURRENT;
  }

  return TEMPOLARITY.PAST;
};

/**
 * @param {any} [blok=null]
 * @returns {Temporality}
 */
export const getBlokTempolarity = (blok = null) => {
  return getTempolarity(blok?.content?.startAt, blok?.content?.endAt);
};

/**
 * @param {any} [startDate=null]
 * @param {any} [endDate=null]
 * @param {Locale|null} [locale=null]
 */
export const formatDatesToString = (
  startDate = null,
  endDate = null,
  locale = null,
) => {
  const options = {
    locale: DATE_FNS_LOCALES[locale?.locale] || enUS,
  };

  startDate = startDate
    ? new Date(parse(startDate, 'yyyy-MM-dd HH:mm', new Date()))
    : null;

  endDate = endDate
    ? new Date(parse(endDate, 'yyyy-MM-dd HH:mm', new Date()))
    : null;

  if (isValid(startDate) && isValid(endDate)) {
    if (isSameDay(startDate, endDate)) {
      return format(startDate, 'MMMM do, yyyy', options);
    }

    const startAt = format(startDate, 'MMMM do', options);
    const startYear = format(startDate, 'yyyy', options);

    const endAt = format(
      endDate,
      isSameMonth(startDate, endDate) && isSameYear(startDate, endDate)
        ? 'do'
        : 'MMMM do',
      options,
    );
    const endYear = format(endDate, 'yyyy', options);

    if (startYear === endYear) {
      return `${startAt} - ${endAt}, ${endYear}`;
    }

    return `${startAt}, ${startYear} - ${endAt}, ${endYear}`;
  }

  if (isValid(startDate)) {
    return format(startDate, 'MMMM d, yyyy', options);
  }

  return null;
};

export const formatStoryblokDateToISO = (input) => {
  const parsed = new Date(parse(input, 'yyyy-MM-dd HH:mm', new Date()));

  if (isValid(parsed)) {
    return formatISO(parsed);
  }

  return null;
};
