import React from 'react';
import Spinner from 'react-svg-spinner';

delete Spinner.defaultProps;

export const Loading = () => {
  return (
    <div className="loading">
      <Spinner color="black" speed="fast" size="16" gap={4} thickness={4} />
    </div>
  );
};
