export const CONFIG = {
  title: 'Derouillon,',
  titleSuffix: ' - Galerie Derouillon, Art contemporain Paris',

  description: 'Galerie Derouillon, Art contemporain Paris',
};

/**
 * @typedef {Object} Locale
 * @property {string} title
 * @property {string} shortName
 * @property {string} locale
 * @property {string} shortCode
 * @property {string} pathPrefix
 */

/**
 * @type {Object<string, Locale>}
 */
export const LOCALE = {
  EN: {
    title: 'English',
    shortName: 'EN',
    locale: 'en-us',
    shortCode: 'en',
    pathPrefix: '/',
  },
};

/**
 * @type {Locale[]}
 */
export const LOCALES = [LOCALE.EN];

export const DEFAULT_LOCALE = LOCALES[0];
