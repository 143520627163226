'use client';

import { useMemo } from 'react';

import { LANGUAGE_KEY } from '../components/language-switcher';

import { useStore } from './store';
import { richTextToString } from './storyblok/rich-text';

export const LANGUAGES = [
  {
    name: 'English',
    shortName: 'EN',
    fieldSuffix: 'EN',
  },
  {
    name: 'Français',
    shortName: 'FR',
    fieldSuffix: 'FR',
  },
];

export const DEFAULT_LANGUAGE = LANGUAGES[0];

const translateFieldName = (field, language = DEFAULT_LANGUAGE) => {
  return `${field}${language.fieldSuffix}`;
};

const BLOK_TRANSLATABLE_FIELDS = {
  text: ['content'],
  'right-content': ['content'],
  'image-text': ['content', 'informations'],

  artist: ['informations', 'biography'],
};

export const useAvailableLanguages = (bloks) => {
  return useMemo(() => {
    const availableLanguages = [DEFAULT_LANGUAGE];

    for (const blok of bloks || []) {
      const fields = BLOK_TRANSLATABLE_FIELDS[blok?.component] || [];

      for (const field of fields) {
        for (const language of LANGUAGES) {
          const translatedFieldName = translateFieldName(field, language);

          if (richTextToString(blok && blok[translatedFieldName])) {
            if (!availableLanguages.includes(language)) {
              availableLanguages.push(language);
            }
          }
        }
      }
    }

    return availableLanguages;
  }, [bloks]);
};

export const useTranslatedBlok = (blok) => {
  const [store] = useStore();

  const translatedData = useMemo(() => {
    const result = {
      ...(blok || {}),
    };

    const fields = BLOK_TRANSLATABLE_FIELDS[blok?.component] || [];

    for (const field of fields) {
      const translatedFieldName = translateFieldName(
        field,
        store[LANGUAGE_KEY],
      );

      if (richTextToString(blok && blok[translatedFieldName])) {
        result[field] = richTextToString(blok && blok[translatedFieldName])
          ? blok && blok[translatedFieldName]
          : blok && blok[field];
      }
    }

    return result;
  }, [blok, store[LANGUAGE_KEY]]);

  return translatedData;
};
