import { formatISO, isValid, min, parse } from 'date-fns';
import React from 'react';

import { CONFIG } from '../config';

import { getImageDimensions, isImage } from './image';
import { formatFieldName } from './string';

export const ORGANIZATION = {
  '@type': 'Organization',
  name: CONFIG.title,
  url: `${process.env.ORIGIN}/`,
};

/**
 * Generate Images Snippet schema.org
 * @param {Object} params
 * @param {Object} params.body
 * @param {String|null} [params.title=null]
 * @param {String|null} [params.description=null]
 * @param {String|null} [params.prefix=null]
 */
export const retrievePageImages = (story) => {
  return [
    [
      isImage(story.content?.image)
        ? story.content?.image
        : story.content?.previewImage,
    ],

    ...(story.content?.body || []).map((slice) => {
      if (slice.component === 'image-text') {
        return [slice.image];
      }

      if (slice.component === 'infinite-slider') {
        return slice.images;
      }

      return [];
    }),
  ]
    .reduce((acc, current) => {
      return acc.concat(current);
    }, [])
    .filter((image) => {
      return image?.filename;
    });
};

export const JsonLd = ({ schema }) => {
  return (
    <script
      type="application/ld+json"
      dangerouslySetInnerHTML={{
        __html: JSON.stringify(schema),
      }}
    />
  );
};

export const EventSchema = ({ story }) => {
  const name = formatFieldName(story.content);

  const images = retrievePageImages(story);

  const startDate = new Date(
    parse(story.content.startAt, 'yyyy-MM-dd HH:mm', new Date()),
  );

  const endDate = new Date(
    parse(story.content.endAt, 'yyyy-MM-dd HH:mm', new Date()),
  );

  const schema = {
    '@context': 'https://schema.org',
    '@type': 'Event',
    name,
    image: images.map((image) => {
      return `${image?.filename}/m/1920x0`;
    }),
    startDate: isValid(startDate) ? formatISO(startDate) : '',
    endDate: isValid(endDate) ? formatISO(endDate) : '',
  };

  return <JsonLd schema={schema} />;
};

export const NewsArticleSchema = ({ story }) => {
  const headline = formatFieldName(story.content);

  const images = retrievePageImages(story);

  const dateModified = new Date(story.published_at);
  const datePublished = min([
    dateModified,
    parse(
      story.content.startAt || story.content.endAt,
      'yyyy-MM-dd HH:mm',
      new Date(),
    ),
  ]);

  const schema = {
    '@context': 'https://schema.org',
    '@type': 'NewsArticle',
    headline,
    image: images.map((image) => {
      return `${image?.filename}/m/1920x0`;
    }),
    datePublished: isValid(datePublished) ? formatISO(datePublished) : '',
    dateModified: isValid(dateModified) ? formatISO(dateModified) : '',
    author: [
      {
        '@type': 'Organization',
        name: CONFIG.title,
        url: `${process.env.ORIGIN}/`,
      },
    ],
  };

  return <JsonLd schema={schema} />;
};

/**
 * Generate Images Snippet schema.org
 * @param {Object} params
 * @param {Object} params.body
 * @param {String|null} [params.title=null]
 * @param {String|null} [params.description=null]
 * @param {String|null} [params.prefix=null]
 */
export const generateImagesSnippet = ({
  body,
  title = null,
  description = null,
  prefix = null,
}) => {
  const structuredDatas = {
    '@context': 'http://schema.org',
    '@type': 'WebPage',
    name: title
      ? title
      : prefix && prefix !== null
        ? `${prefix}${CONFIG.titleSuffix}`
        : CONFIG.title,
    description: description || CONFIG.description,
  };

  const images = retrievePageImages(body);

  if (images.length > 0)
    structuredDatas.image = images.map((image, key) => {
      const dimensions = getImageDimensions(image.filename);
      const thumbnailWidth = (200 * dimensions.height) / dimensions.width;
      const thumbnailHeight =
        (dimensions.height * thumbnailWidth) / dimensions.width;
      return {
        '@type': 'ImageObject',
        name: `${prefix || CONFIG.title} - ${key + 1}`,
        contentUrl: `${image.filename}/m/1920x0`,
        uploadDate: '2023-11-07T10:00:00Z',
        encodingFormat: `image/${
          image.filename.split('.')[image.filename.split('.').length - 1]
        }`,
        width: dimensions.width,
        height: dimensions.height,
        thumbnail: {
          '@type': 'ImageObject',
          contentUrl: image.filename,
          width: thumbnailWidth,
          height: thumbnailHeight,
        },
      };
    });

  return (
    <script
      type="application/ld+json"
      dangerouslySetInnerHTML={{
        __html: JSON.stringify(structuredDatas),
      }}
    />
  );
};
