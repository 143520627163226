import { storyblokEditable } from '@storyblok/react';
import classNames from 'classnames';
import React from 'react';

import {
  FILTER_SECTION_LAYOUT,
  FilterSection,
} from '../../components/filter-section';
import { SectionTitle } from '../../components/section-title';

import { useData } from '../../helpers/data.js';

import { CONTENT_TYPE } from '../../config/content-type.js';

export const AllExhibitions = ({ blok, index }) => {
  const { artists, paginated } = useData();

  const perPage = CONTENT_TYPE.EXHIBITION.limit;

  return (
    <div
      className={classNames('all-exhibitions', {
        'all-exhibitions--first': index === 0,
      })}
      {...storyblokEditable(blok)}
    >
      <SectionTitle
        title={blok.title}
        titleLevel={blok.titleLevel || 'h1'}
        buttonText={blok.buttonText}
        buttonLink={blok.buttonLink}
      />

      <FilterSection
        contentType={CONTENT_TYPE.EXHIBITION}
        initialItems={(paginated?.exhibitions?.stories || []).slice(0, perPage)}
        total={paginated?.exhibitions?.total || 0}
        perPage={perPage || 1}
        filterLabel={blok.filterLabel}
        filterItems={artists || []}
        links={blok.links}
        tokens={blok}
        moreButtonText={blok.moreButtonText}
        layout={FILTER_SECTION_LAYOUT.WITH_TEMPORALITY}
        lastGridCountMobile={2}
        isFirst={index === 0}
      />
    </div>
  );
};
