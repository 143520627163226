import { storyblokEditable } from '@storyblok/react';
import classnames from 'classnames';
import React, { useCallback, useMemo, useRef, useState } from 'react';
import AnimateHeight from 'react-animate-height';
import { MARK_TEXT_STYLE } from 'storyblok-rich-text-react-renderer';

import { ICONS } from '../../components/icons';
import { LanguageSwitcher } from '../../components/language-switcher';
import { Link } from '../../components/link';
import { MaxWidth } from '../../components/max-width';
import { SectionTitle } from '../../components/section-title';

import { render } from '../../helpers/storyblok/rich-text';
import { useTranslatedBlok } from '../../helpers/translation';

const MENU_ANIM_TIME = 300;

export const Text = ({ blok, availableLanguages, index }) => {
  const translatedBlok = useTranslatedBlok(blok);

  const menuRef = useRef(null);

  const [open, setOpen] = useState(false);

  const height = useMemo(() => {
    return open ? 'auto' : 0;
  }, [open]);

  const toggle = useCallback(() => {
    setOpen((state) => {
      return !state;
    });
  }, [setOpen]);

  const anchors = useMemo(() => {
    return (translatedBlok.content?.content || [])
      .filter((item) => {
        return (
          item.type === 'heading' &&
          item.content &&
          item.content[0] &&
          item.content[0]?.marks &&
          item.content[0]?.marks[0]?.type === 'anchor'
        );
      })
      .map((heading) => {
        return {
          id: heading.content[0]?.marks[0]?.attrs?.id || null,
          text: heading.content[0]?.text || null,
        };
      })
      .filter((anchor) => {
        return anchor.id && anchor.text;
      });
  }, [translatedBlok.content]);

  return (
    <div
      {...storyblokEditable(blok)}
      className={classnames('text', {
        'text--first': index === 0,
        'text--mobile-menu-open': open,
      })}
      style={{
        '--mobile-menu-anim-time': `${MENU_ANIM_TIME}ms`,
      }}
    >
      {translatedBlok.title ? (
        <SectionTitle
          title={translatedBlok.title}
          titleLevel={translatedBlok.titleLevel}
          borderBottom="desktop"
        />
      ) : null}

      <MaxWidth className="text__grid">
        <aside className="text__aside">
          <ul>
            {anchors.map((anchor) => {
              return (
                <li key={anchor.id}>
                  <Link href={`#${anchor.id}`}>{anchor.text}</Link>
                </li>
              );
            })}
          </ul>

          <LanguageSwitcher availableLanguages={availableLanguages} />

          <button className="text__aside__mobile-menu-btn" onClick={toggle}>
            {anchors[0].text.toUpperCase()} {ICONS.ARROW_BOTTOM}
          </button>
        </aside>

        <AnimateHeight
          duration={MENU_ANIM_TIME}
          height={height}
          className="text__mobile-menu"
          ref={menuRef}
        >
          <ul>
            {anchors.map((anchor) => {
              return (
                <li key={anchor.id}>
                  <Link
                    href={`#${anchor.id}`}
                    timeout={{
                      function: () => {
                        setOpen(false);
                      },
                      time: 100,
                    }}
                    offsetElement={menuRef.current}
                  >
                    {anchor.text}
                  </Link>
                </li>
              );
            })}
          </ul>
        </AnimateHeight>

        <div className="text__content">
          {render(translatedBlok.content, {
            markResolvers: {
              [MARK_TEXT_STYLE]: (children, options) => {
                return <span style={{ color: options.color }}>{children}</span>;
              },
            },
          })}
        </div>
      </MaxWidth>
    </div>
  );
};
