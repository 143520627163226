import { storyblokEditable } from '@storyblok/react';
import classnames from 'classnames';
import compareFunc from 'compare-func';
import React, { useMemo } from 'react';

import { Image } from '../../components/image';
import { Link } from '../../components/link';
import { MaxWidth } from '../../components/max-width';
import { SectionTitle } from '../../components/section-title';

import { useData } from '../../helpers/data.js';
import { isImage } from '../../helpers/image';

export const ListArtists = ({ index, blok }) => {
  const { artists } = useData();

  const artistLimit = blok.artistLimit
    ? parseInt(blok.artistLimit) < 0
      ? 0
      : parseInt(blok.artistLimit)
    : artists?.length || 0;

  const first = useMemo(() => {
    return index === 0;
  }, [index]);

  return (
    <div
      className={classnames('list-artists', {
        'list-artists--first': first,
      })}
      {...storyblokEditable(blok)}
    >
      <SectionTitle
        title={blok.title}
        titleLevel={blok.titleLevel}
        buttonText={blok.buttonText}
        buttonLink={blok.buttonLink}
        borderBottom={first ? 'always' : 'desktop'}
        borderTop={first ? null : 'mobile'}
      />

      <MaxWidth className="list-artists__artists">
        {(artists || [])
          .map((item) => {
            return {
              sortValue: item.content.sortingName || item.content.name,
              ...item,
            };
          })
          .sort(compareFunc('sortValue'))
          .slice(0, artistLimit)
          .map((artist, index) => {
            return <Artist key={index} blok={artist} />;
          })}
      </MaxWidth>
    </div>
  );
};

const Artist = ({ blok }) => {
  const image = isImage(blok.content.previewImage)
    ? blok.content.previewImage
    : blok.content.image;

  return (
    <div className="list-artists-item">
      {isImage(image) ? (
        <div className="list-artists-item__image">
          <Image
            image={image}
            sizes="(min-width: 1141px) 354px, (min-width: 769px) 33vw, 50vw"
            lazy
          />
        </div>
      ) : null}

      <Link className="list-artists-item__name" page={blok}>
        <h3>{blok.content.name}</h3>
      </Link>
    </div>
  );
};
