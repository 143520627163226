import { storyblokEditable } from '@storyblok/react';
import classnames from 'classnames';
import React, { useEffect, useRef, useState } from 'react';

import { Image } from '../../components/image';
import { Link } from '../../components/link';
import { MaxWidth } from '../../components/max-width';
import { Tag } from '../../components/tag';

import { render } from '../../helpers/storyblok/rich-text';

export const HeroLeft = ({ blok }) => {
  const ref = useRef();

  useEffect(() => {
    const handler = () => {
      if (ref.current) {
        ref.current.style.setProperty('--js-scroll-top', `${window.scrollY}px`);
      }
    };

    document.addEventListener('scroll', handler, {
      passive: true,
    });

    return () => {
      document.removeEventListener('scroll', handler);
    };
  }, []);

  return (
    <div
      ref={ref}
      {...storyblokEditable(blok)}
      className={classnames('hero-left', `hero-left--theme-${blok.theme}`)}
    >
      <Image
        className="hero-left__background hero-left__background--is-desktop"
        image={
          blok.background?.filename ? blok.background : blok.backgroundMobile
        }
        fetchPriority="high"
      />
      <Image
        className="hero-left__background hero-left__background--is-mobile"
        image={
          blok.backgroundMobile?.filename
            ? blok.backgroundMobile
            : blok.background
        }
        fetchPriority="high"
      />

      <div className="hero-left__gradient" />

      <div className="hero-left__wrap">
        <MaxWidth>
          <div className="hero-left__content">
            <header>
              <Tag theme={blok.theme === 'dark' ? 'dark' : 'light'}>
                {render(blok.supTitle)}
              </Tag>

              <h1 className="hero-left__content__title">
                {blok.link ? (
                  <Link link={blok.link}>{blok.title}</Link>
                ) : (
                  blok.title
                )}
              </h1>
            </header>

            <div className="hero-left__content__description">
              {render(blok.description)}
            </div>
          </div>
        </MaxWidth>
      </div>

      {blok.link ? (
        <Link
          className="hero-left__global-link"
          link={blok.link}
          aria-hidden="true"
        />
      ) : null}
    </div>
  );
};
