import { storyblokEditable } from '@storyblok/react';
import classnames from 'classnames';
import React, { useMemo } from 'react';

import { MaxWidth } from '../../components/max-width';

import { useData } from '../../helpers/data.js';
import { render } from '../../helpers/storyblok/rich-text';
import { useTranslatedBlok } from '../../helpers/translation';

/**
 * @param {Object} props
 * @param {any} props.blok
 * @param {number|null} [props.index=null]
 * @param {number|0} [props.heroSideBarHeight=0]
 */
export const RightContent = ({ blok, heroSideBarHeight = 0 }) => {
  const { story } = useData();

  const safeHeight = useMemo(() => {
    const blokIndex = story?.content?.body?.findIndex?.((item) => {
      return item._uid === blok._uid;
    });

    return (
      blokIndex === 0 &&
      story?.content?.body?.[blokIndex + 1]?.component !== 'video-section'
    );
  }, [story, blok._uid]);

  const translatedBlok = useTranslatedBlok(blok);

  return (
    <div
      className={classnames('right-content', {
        'right-content--safe-height': safeHeight,
      })}
      style={{
        '--hero-sidebar-height':
          heroSideBarHeight > 0 ? `${heroSideBarHeight}px` : heroSideBarHeight,
      }}
      {...storyblokEditable(blok)}
    >
      <MaxWidth>
        <div className="right-content__grid">
          <div className="right-content__empty" />

          <div className="right-content__grid__container">
            {render(translatedBlok.content)}
          </div>
        </div>
      </MaxWidth>
    </div>
  );
};
