import diacritics from 'diacritics';

/**
 * Smooth scroll to element
 * @param {String} str
 */
export const toCapitalize = (str) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

/**
 * Format story field name with artists
 * @param {Object} blok
 * @param {String} blok.name
 * @param {Array<Object>} blok.artists
 * @param {Boolean} blok.keepThisExactName
 */
export const formatFieldName = (blok) => {
  if (
    !blok.artists ||
    blok.artists.length <= 0 ||
    blok.keepThisExactName ||
    /→/g.test(blok.name)
  ) {
    return blok.name;
  }

  if (blok.artists.length > 0) {
    return `${blok.artists
      .map((artist) => artist?.name || artist?.content?.name)
      .filter((item) => item)
      .join(' & ')} → ${blok.name}`;
  }

  return blok.name;
};

/**
 * Format string to slug
 * @param {string} inputString
 */
export const stringToSlug = (input) => {
  if (typeof input !== 'string') {
    return input;
  }

  return diacritics
    .remove(input)
    .toLowerCase()
    .replace(/[^a-zA-Z0-9]+/g, '-')
    .replace(/^-+|-+$/g, '');
};

/**
 * Format slug to string
 * @param {string} slug
 */
export const slugToString = (slug) => {
  return slug.replace(/-/g, ' ').replace(/(?:^|\s)\S/g, function (a) {
    return a.toUpperCase();
  });
};

/**
 * Replace on richtext
 * @param {Object} richtext
 * @param {Object} params
 */
export const replaceValuesInRichText = (richtext, params) => {
  return {
    ...richtext,
    content: richtext.content.map((item) => ({
      ...item,
      content: item.content.map((item2) => {
        params.forEach(
          (param) =>
            (item2.text = item2.text.replace(
              new RegExp(param.key, 'g'),
              param.value,
            )),
        );
        return item2;
      }),
    })),
  };
};
