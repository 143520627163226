import { DEFAULT_LOCALE } from '../config/index.js';

const removeTrailingSlash = (slug) => {
  return slug.replace(/^(.+)\/$/, '$1');
};

const removeDefaultLocalePrefix = (slug) => {
  return slug.replace(new RegExp(`^${DEFAULT_LOCALE.shortCode}/`), '/');
};

export const normalizeFullSlug = (slug) => {
  return removeTrailingSlash(removeDefaultLocalePrefix(slug || ''));
};
