import React, { useState } from 'react';

export const useSidebarHeight = (ref) => {
  const [heroSideBarHeight, setHeroSideBarHeight] = useState(200);

  React.useEffect(() => {
    if (ref.current) {
      const computedStyle = getComputedStyle(ref.current);
      setHeroSideBarHeight(
        parseInt(computedStyle.height) -
          parseInt(computedStyle.getPropertyValue('--offset-top') || 0),
      );
    }
  }, [ref]);

  return heroSideBarHeight;
};
