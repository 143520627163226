import React from 'react';

import { useData } from '../../helpers/data.js';
import { formatDatesToString } from '../../helpers/date';
import { isLink } from '../../helpers/storyblok/is-link';

import { Link } from '../link';

export const generateCardDetails = ({
  startAt = null,
  endAt = null,
  location = null,
  locationLink = null,
  displayDates = true,
  locale,
}) => {
  const date = displayDates
    ? formatDatesToString(startAt, endAt, locale)
    : null;

  return date || location ? (
    <div className="card-details">
      {date ? <p className="card-details__date">{date}</p> : null}

      {location ? (
        <p className="card-details__location">
          {isLink(locationLink) ? (
            <Link link={locationLink}>{location}</Link>
          ) : (
            location
          )}
        </p>
      ) : null}
    </div>
  ) : null;
};

export const CardDetails = ({
  startAt = null,
  endAt = null,
  location = null,
  locationLink = null,
  displayDates = true,
}) => {
  const { locale } = useData();

  return generateCardDetails({
    startAt,
    endAt,
    location,
    locationLink,
    displayDates,
    locale,
  });
};
