import { usePathname } from 'next/navigation';
import React from 'react';

import { MaxWidth } from '../../components/max-width';

/**
 * @param {Object} props
 * @param {number} props.page
 * @param {number} props.lastPage
 */
export const Pagination = ({ page, lastPage }) => {
  const pathname = usePathname();

  return lastPage > 1 ? (
    <nav className="pagination" role="navigation" aria-label="pagination">
      <MaxWidth>
        <ul>
          {page > 1 ? (
            <>
              <li>
                <a
                  className="pagination__link"
                  href={`${pathname}${page - 1 > 1 ? `?page=${page - 1}` : ''}`}
                >
                  Previous <span className="visually-hidden">page</span>
                </a>
              </li>

              <li>
                <a className="pagination__link" href={pathname}>
                  <span className="visually-hidden">Page</span> 1
                  <span className="visually-hidden"> (first page)</span>
                </a>
              </li>
            </>
          ) : null}

          {page > 2 ? <li>&#8230;</li> : null}

          <li>
            <a
              className="pagination__link pagination__link--current"
              href="#"
              aria-current="page"
            >
              <span className="visually-hidden">Page</span> {page}
            </a>
          </li>

          {lastPage - page > 1 ? <li>&#8230;</li> : null}

          {page < lastPage ? (
            <>
              <li>
                <a
                  className="pagination__link"
                  href={`${pathname}?page=${lastPage}`}
                >
                  <span className="visually-hidden">Page</span> {lastPage}
                  <span className="visually-hidden"> (last page)</span>
                </a>
              </li>

              <li>
                <a
                  className="pagination__link"
                  href={`${pathname}?page=${page + 1}`}
                >
                  Next <span className="visually-hidden">page</span>
                </a>
              </li>
            </>
          ) : null}
        </ul>
      </MaxWidth>
    </nav>
  ) : null;
};
