import classnames from 'classnames';
import React from 'react';

import { useStore } from '../../helpers/store';

export const LANGUAGE_KEY = 'language';

/**
 * @param {Object} props
 * @param {any[]} props.availableLanguages
 */
export const LanguageSwitcher = ({ availableLanguages }) => {
  const [store, actions] = useStore();

  if (!(availableLanguages && availableLanguages.length > 1)) {
    return null;
  }

  return (
    <div className="language-switcher">
      {availableLanguages.map((language, index) => {
        return (
          <React.Fragment key={language.name}>
            <button
              className={classnames('language-switcher__alternate', {
                'language-switcher__alternate--current':
                  language === store[LANGUAGE_KEY],
              })}
              onClick={(event) => {
                event.preventDefault();

                actions?.set?.(LANGUAGE_KEY, language);
              }}
            >
              {language.name}
            </button>

            {index + 1 < availableLanguages.length ? (
              <hr className="language-switcher__separator" />
            ) : null}
          </React.Fragment>
        );
      })}
    </div>
  );
};
