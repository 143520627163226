const CONTENT_TYPE_COMMON_FIELDS = `
  name
  previewImage {
    filename
  }
  image {
    filename
  }
`;

const ARTISTS_FIELDS = `
  keepThisExactName
  artists(fields: ["name"]) {
    uuid
    full_slug: fullSlug
    content
  }
`;

const DETAILS_FIELDS = `
  startAt
  endAt
  location
`;

/**
 * @typedef {Object} ContentTypeFilter
 * @property {string} key
 * @property {string} searchParamName
 * @property {Function} queryBuilder
 */

/**
 * @typedef {Object} ContentType
 * @property {string} key
 * @property {string} component
 * @property {string} [fields]
 * @property {number} [limit]
 * @property {ContentTypeFilter} [filter]
 * @property {Object<string, number>} [limitFilteredBy]
 */

/**
 * @enum {ContentType}
 * @type {Object.<string, ContentType>}
 */
export const CONTENT_TYPE = {
  PAGE: {
    key: 'pages',
    component: 'page',
  },

  ARTIST: {
    key: 'artists',
    component: 'artist',
    fields: `
      ${CONTENT_TYPE_COMMON_FIELDS}
      sortingName
    `,
    filter: {
      key: 'artist',
      searchParamName: 'artist',
      queryBuilder: (story) => {
        return {
          artists: {
            in_array: [story.uuid],
          },
        };
      },
    },
  },

  EDITION: {
    key: 'editions',
    component: 'edition',
    fields: `
      ${CONTENT_TYPE_COMMON_FIELDS}
      ${ARTISTS_FIELDS}
      category
      startAt
    `,
    limit: 12,
    limitFilteredBy: {
      artist: Infinity,
    },
  },

  EXHIBITION: {
    key: 'exhibitions',
    component: 'exhibition',
    fields: `
      ${CONTENT_TYPE_COMMON_FIELDS}
      ${ARTISTS_FIELDS}
      ${DETAILS_FIELDS}
    `,
    limit: 10,
    limitFilteredBy: {
      artist: 5,
    },
  },

  FAIR: {
    key: 'fairs',
    component: 'fair',
    fields: `
      ${CONTENT_TYPE_COMMON_FIELDS}
      ${ARTISTS_FIELDS}
      ${DETAILS_FIELDS}
    `,
    limit: 12,
  },

  NEWS: {
    key: 'news',
    component: 'news',
    fields: `
      ${CONTENT_TYPE_COMMON_FIELDS}
      category
      ${ARTISTS_FIELDS}
      ${DETAILS_FIELDS}
    `,
    limit: 12,
    limitFilteredBy: {
      artist: 3,
    },
  },
};
