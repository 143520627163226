import classnames from 'classnames';
import React from 'react';

/**
 * @param {Object} props
 * @param {number} props.columns
 * @param {number|null} [props.mobileColumns=null]
 * @param {string|null} [props.className=null]
 * @param {string|null} [props.id=null]
 * @param {any} props.children
 */
export const Grid = ({
  columns,
  mobileColumns = null,
  className = null,
  id = null,
  children,
}) => {
  return (
    <div
      className={classnames(
        'grid',
        `grid--columns-${columns}`,
        mobileColumns !== null ? `grid--mobile-columns-${mobileColumns}` : null,
        className,
      )}
      id={id || ''}
    >
      {children}
    </div>
  );
};
