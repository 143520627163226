import React, { useRef } from 'react';

import { getSlice } from '../slices/index.js';

import { CardDetails } from '../components/card-details';
import { HeroSidebar } from '../components/hero-sidebar';
import { Layout, LayoutRest } from '../components/layout';

import { useData } from '../helpers/data.js';
import { useSidebarHeight } from '../helpers/hooks/use-sidebar-height';
import { isImage } from '../helpers/image';
import { generateImagesSnippet } from '../helpers/snippet.js';
import { render } from '../helpers/storyblok/rich-text';
import { formatFieldName } from '../helpers/string';
import { useAvailableLanguages } from '../helpers/translation';

export const EditionLayout = () => {
  const { story } = useData();

  const heroSidebarRef = useRef(null);
  const heroSideBarHeight = useSidebarHeight(heroSidebarRef);

  const body = story.content?.body || [];

  const availableLanguages = useAvailableLanguages(body);

  return (
    <Layout>
      {generateImagesSnippet({
        body,
        title: formatFieldName(story.content),
        prefix: story?.content?.name,
      })}

      <HeroSidebar
        ref={heroSidebarRef}
        tag={story.content.category}
        image={
          isImage(story.content.image)
            ? story.content.image
            : story.content.previewImage
        }
        imageFocusPoint={story.content.imageFocus}
        title={formatFieldName(story.content)}
        details={
          <>
            <CardDetails
              startAt={story.content.startAt}
              endAt={story.content.endAt}
            />

            {render(story.content.details)}
          </>
        }
        availableLanguages={availableLanguages}
        artists={story.content?.artists}
        displayArtistsList={story.content?.displayArtistsList}
      />

      <LayoutRest>
        {body.map((item, index) => {
          const Slice = getSlice(item.component);

          return Slice ? (
            <Slice
              key={item._uid}
              index={index}
              blok={item}
              heroSideBarHeight={heroSideBarHeight}
            />
          ) : null;
        })}
      </LayoutRest>
    </Layout>
  );
};
