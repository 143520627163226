import { ArtistLayout } from './artist.js';
import { EditionLayout } from './edition.js';
import { ExhibitionLayout } from './exhibition.js';
import { FairLayout } from './fair.js';
import { NewsLayout } from './news.js';
import { PageLayout } from './page.js';

const LAYOUT = {
  artist: ArtistLayout,
  edition: EditionLayout,
  exhibition: ExhibitionLayout,
  fair: FairLayout,
  news: NewsLayout,
  page: PageLayout,
};

const DEFAULT_LAYOUT = LAYOUT.page;

export const getLayout = (name) => {
  return LAYOUT[name] || DEFAULT_LAYOUT;
};
