import React from 'react';

import { getSlice } from '../slices/index.js';

import { Layout, LayoutRest } from '../components/layout';

import { useData } from '../helpers/data.js';
import { generateImagesSnippet } from '../helpers/snippet.js';
import { useAvailableLanguages } from '../helpers/translation';

export const PageLayout = () => {
  const { story } = useData();

  const body = story.content?.body || [];

  const availableLanguages = useAvailableLanguages(body);

  const seo = story?.content?.seo[0];

  let hero;
  let rest;

  if (body[0]?.component === 'hero-left') {
    hero = body[0];
    rest = body.slice(1);
  } else {
    rest = body;
  }

  const Hero = getSlice(hero?.component);

  return (
    <Layout>
      {generateImagesSnippet({
        body,
        title: seo?.title,
        description: seo?.description,
      })}

      {hero ? (
        <Hero index={0} blok={hero} availableLanguages={availableLanguages} />
      ) : null}

      <LayoutRest>
        {rest.map((item, index) => {
          const Slice = getSlice(item.component);

          return Slice ? (
            <Slice
              key={item._uid}
              index={index + (hero ? 1 : 0)}
              blok={item}
              availableLanguages={availableLanguages}
            />
          ) : null;
        })}
      </LayoutRest>
    </Layout>
  );
};
