import { CONTENT_TYPE } from './content-type.js';

/**
 * @typedef {import('./content-type.js').ContentType} ContentType
 * @typedef {import('./content-type.js').ContentTypeFilter} ContentTypeFilter
 */

/**
 * @typedef {Object} SliceContentType
 * @property {ContentType} contentType
 * @property {number} [count]
 * @property {boolean} [usePagination]
 * @property {ContentTypeFilter} [filter]
 */

/**
 * @type {Object<string, SliceContentType[]>}
 */
export const SLICE_FETCH = {
  'all-exhibitions': [
    {
      contentType: CONTENT_TYPE.EXHIBITION,
      count: CONTENT_TYPE.EXHIBITION.limit,
      usePagination: true,
    },
    {
      contentType: CONTENT_TYPE.ARTIST,
      count: Infinity,
    },
  ],
  'all-news': [
    {
      contentType: CONTENT_TYPE.NEWS,
      count: CONTENT_TYPE.NEWS.limit,
      usePagination: true,
    },
    {
      contentType: CONTENT_TYPE.ARTIST,
      count: Infinity,
    },
  ],
  'all-fairs': [
    {
      contentType: CONTENT_TYPE.FAIR,
      count: CONTENT_TYPE.FAIR.limit,
      usePagination: true,
    },
    {
      contentType: CONTENT_TYPE.ARTIST,
      count: Infinity,
    },
  ],
  'all-editions': [
    {
      contentType: CONTENT_TYPE.EDITION,
      count: CONTENT_TYPE.EDITION.limit,
      usePagination: true,
    },
  ],

  'list-news': [
    {
      contentType: CONTENT_TYPE.NEWS,
      count: 5,
    },
    {
      contentType: CONTENT_TYPE.ARTIST,
      count: Infinity,
    },
  ],
  'list-artists': [
    {
      contentType: CONTENT_TYPE.ARTIST,
      count: Infinity,
    },
  ],

  'artist-exhibitions': [
    {
      contentType: CONTENT_TYPE.EXHIBITION,
      count: CONTENT_TYPE.EXHIBITION.limitFilteredBy?.artist,
      filter: CONTENT_TYPE.ARTIST.filter,
    },
  ],
  'artist-news': [
    {
      contentType: CONTENT_TYPE.NEWS,
      count: CONTENT_TYPE.NEWS.limitFilteredBy?.artist,
      filter: CONTENT_TYPE.ARTIST.filter,
    },
  ],
  'artist-editions': [
    {
      contentType: CONTENT_TYPE.EDITION,
      count: CONTENT_TYPE.EDITION.limitFilteredBy?.artist,
      filter: CONTENT_TYPE.ARTIST.filter,
    },
  ],
};
