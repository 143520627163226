import classnames from 'classnames';
import React from 'react';

import { Link } from '../link';
import { Logo } from '../logo';
import { MaxWidth } from '../max-width';

export const Header = ({ blok, page }) => {
  return (
    <header className="header">
      <MaxWidth>
        <Link href="/" className="header__logo">
          <Logo color="var(--color)" />
        </Link>

        <div className="header__links">
          {blok.content.links.map((item) => {
            return (
              <Link
                link={item.link}
                key={item._uid}
                className={classnames('header__link', {
                  'header__link--current':
                    item.link.cached_url === page.full_slug,
                })}
              >
                {item.title}
              </Link>
            );
          })}
        </div>
      </MaxWidth>
    </header>
  );
};
