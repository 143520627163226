import React, { useRef } from 'react';

import { getSlice } from '../slices/index.js';
import { RightContent } from '../slices/right-content';

import { HeroSidebar } from '../components/hero-sidebar';
import { Layout, LayoutRest } from '../components/layout';

import { useData } from '../helpers/data.js';
import { useSidebarHeight } from '../helpers/hooks/use-sidebar-height';
import { isImage } from '../helpers/image';
import { generateImagesSnippet } from '../helpers/snippet.js';
import { render } from '../helpers/storyblok/rich-text';
import { formatFieldName } from '../helpers/string';
import {
  useAvailableLanguages,
  useTranslatedBlok,
} from '../helpers/translation';

export const ArtistLayout = () => {
  const { story, layouts } = useData();

  const body = story.content?.body || [];

  const heroSidebarRef = useRef(null);
  const heroSideBarHeight = useSidebarHeight(heroSidebarRef);

  const translatableBlok = {
    component: 'artist',
    ...story.content,
  };

  const availableLanguages = useAvailableLanguages([translatableBlok]);
  const translatedContent = useTranslatedBlok(translatableBlok);

  const lastInformationsSlice =
    translatedContent?.informations?.content[
      translatedContent?.informations?.content?.length - 1
    ];

  const lastInformationsLink = lastInformationsSlice.content.find((obj) =>
    obj?.marks?.find((obj) => obj?.type === 'link'),
  );

  return (
    <Layout>
      <div className="page-artist">
        {generateImagesSnippet({
          body,
          title: formatFieldName(story.content),
        })}

        <HeroSidebar
          ref={heroSidebarRef}
          image={
            isImage(story.content.image)
              ? story.content.image
              : story.content.previewImage
          }
          imageFocusPoint={story.content.imageFocus}
          title={formatFieldName(story.content)}
          details={
            lastInformationsLink
              ? render(translatedContent.informations).slice(0, -1)
              : render(translatedContent.informations)
          }
          lastLink={
            lastInformationsLink
              ? render(translatedContent.informations).slice(-1)
              : null
          }
          availableLanguages={availableLanguages}
        />

        <LayoutRest>
          <RightContent
            blok={{ content: translatedContent.biography }}
            index={0}
          />

          {(layouts.artist?.content?.blocks || []).map((item, index) => {
            const Slice = getSlice(item.component);

            return Slice ? (
              <Slice
                key={item._uid}
                index={index}
                blok={item}
                heroSideBarHeight={heroSideBarHeight}
              />
            ) : null;
          })}
        </LayoutRest>
      </div>
    </Layout>
  );
};
