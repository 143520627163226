'use client';

import classnames from 'classnames';
import { useSelect } from 'downshift';
import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';
import { useDimensionsRef, useWindowSize } from 'rooks';

import { ICONS } from '../../icons';

const CLOSE_SCROLL_THRESHOLD = 32;

const itemToString = (item) => {
  return item ? item.label : '';
};

export const SelectInput = ({
  options,
  defaultLabel,
  setValue,
  current = null,
  setFilterIsOpen = null,
}) => {
  const {
    isOpen,
    closeMenu,
    selectedItem,
    getToggleButtonProps,
    getMenuProps,
    highlightedIndex,
    getItemProps,
    selectItem,
  } = useSelect({
    items: options,
    itemToString,
  });

  const lastScrollTop = useRef(0);

  const handleScroll = useCallback(() => {
    if (isOpen) {
      const scrollTop = typeof window !== 'undefined' ? window.scrollY : 0;

      if (
        Math.abs(lastScrollTop.current - scrollTop) > CLOSE_SCROLL_THRESHOLD
      ) {
        closeMenu();
      }
    }
  }, [isOpen, closeMenu]);

  useLayoutEffect(() => {
    if (setFilterIsOpen && typeof setFilterIsOpen === 'function') {
      setFilterIsOpen(isOpen);
    }

    if (typeof document !== 'undefined' && isOpen) {
      document.addEventListener('scroll', handleScroll, { passive: true });
    }

    return () => {
      if (typeof document !== 'undefined') {
        document.removeEventListener('scroll', handleScroll);
      }
    };
  }, [isOpen]);

  const [ref, dimensions] = useDimensionsRef();
  const { innerHeight } = useWindowSize();

  const [maxHeight, setMaxHeight] = useState(128);

  useLayoutEffect(() => {
    setMaxHeight(
      Math.max(
        128,
        (innerHeight || 0) - ((dimensions?.y || 0) + (dimensions?.height || 0)),
      ),
    );
  }, [innerHeight, dimensions]);

  const firstCall = useRef(true);

  useEffect(() => {
    if (firstCall.current) {
      firstCall.current = false;

      return;
    }

    setValue(selectedItem?.value);
  }, [selectedItem]);

  useEffect(() => {
    selectItem(
      current
        ? {
            label: current,
            value: current,
          }
        : null,
    );
  }, [current]);

  const currentItem = selectedItem
    ? options.find((item) => {
        return item.value === selectedItem.value;
      })
    : null;

  return (
    <div
      ref={ref}
      className={classnames('select-input', {
        'select-input--is-open': isOpen,
      })}
    >
      <div className="select-input__top">
        <div className="select-input__top__label" {...getToggleButtonProps()}>
          <span>
            {currentItem && currentItem?.value !== null
              ? currentItem.label
              : defaultLabel}{' '}
            {ICONS.ARROW_BOTTOM}
          </span>
        </div>
      </div>

      <div
        {...getMenuProps()}
        className="select-input__options"
        style={{
          // @ts-ignore
          '--max-height': `${maxHeight}px`,
        }}
      >
        <ul className="select-input__options__ref">
          {options.map((item, index) => {
            if (selectedItem && item.value === selectedItem.value) {
              return null;
            }

            return (
              <li
                key={`${item.value}${index}`}
                className={classnames('select-input__option', {
                  'select-input__option--is-highlighted':
                    highlightedIndex === index,
                })}
                {...getItemProps({ item, index })}
              >
                <span>{item.label}</span>
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
};
