import { ARROW_BOTTOM } from './icons/arrow-bottom';
import {
  ARROW_LEFT_SLIDER,
  ARROW_LEFT_SLIDER_TEXT,
} from './icons/arrow-left-slider';
import { ARROW_RIGHT } from './icons/arrow-right';
import {
  ARROW_RIGHT_SLIDER,
  ARROW_RIGHT_SLIDER_TEXT,
} from './icons/arrow-right-slider';
import { LOCATION } from './icons/location';
import { CROSS, CROSS_TEXT } from './icons/cross';

export const ICONS = {
  LOCATION,
  ARROW_RIGHT,
  ARROW_LEFT_SLIDER,
  ARROW_LEFT_SLIDER_TEXT,
  ARROW_RIGHT_SLIDER,
  ARROW_RIGHT_SLIDER_TEXT,
  ARROW_BOTTOM,
  CROSS,
  CROSS_TEXT
};
