import React, { useRef } from 'react';

import { getSlice } from '../slices/index.js';

import { CardDetails } from '../components/card-details';
import { HeroSidebar } from '../components/hero-sidebar';
import { Layout, LayoutRest } from '../components/layout';

import { useData } from '../helpers/data.js';
import { formatStoryblokDateToISO } from '../helpers/date.js';
import { useSidebarHeight } from '../helpers/hooks/use-sidebar-height';
import { isImage } from '../helpers/image';
import { normalizeFullSlug } from '../helpers/slug.js';
import { JsonLd, retrievePageImages } from '../helpers/snippet.js';
import { render, richTextToString } from '../helpers/storyblok/rich-text';
import { formatFieldName } from '../helpers/string';
import { useAvailableLanguages } from '../helpers/translation';

export const FairLayout = () => {
  const { story } = useData();

  const heroSidebarRef = useRef(null);
  const heroSideBarHeight = useSidebarHeight(heroSidebarRef);

  const body = story.content?.body || [];

  const availableLanguages = useAvailableLanguages(body);

  const title = formatFieldName(story.content);

  return (
    <Layout>
      <JsonLd
        schema={{
          '@context': 'https://schema.org',
          '@type': 'Event',
          name: title,
          description: richTextToString(story.content.details),
          image: retrievePageImages(story).map((image) => {
            return `${image?.filename}/m/1920x0`;
          }),
          startDate: formatStoryblokDateToISO(story.content.startAt) || '',
          endDate: formatStoryblokDateToISO(story.content.endAt) || '',
          eventStatus: 'https://schema.org/EventScheduled',
          eventAttendanceMode: 'https://schema.org/OfflineEventAttendanceMode',
          location: story.content.location
            ? {
                '@type': 'Place',
                name: story.content.location,
                url: story.content.locationLink?.url || '',
              }
            : [],
          performer: (story.content.artists || []).map((artist) => {
            return {
              '@type': 'Person',
              name: artist.name,
              url: `${process.env.ORIGIN}${normalizeFullSlug(
                artist.full_slug,
              )}`,
            };
          }),
        }}
      />

      <HeroSidebar
        ref={heroSidebarRef}
        image={
          isImage(story.content.image)
            ? story.content.image
            : story.content.previewImage
        }
        imageFocusPoint={story.content.imageFocus}
        title={title}
        details={
          <>
            <CardDetails
              startAt={story.content.startAt}
              endAt={story.content.endAt}
              location={story.content.location}
              locationLink={story.content.locationLink}
            />

            {render(story.content.details)}
          </>
        }
        availableLanguages={availableLanguages}
        artists={story.content?.artists}
        displayArtistsList={story.content?.displayArtistsList}
      />

      <LayoutRest>
        {body.map((item, index) => {
          const Slice = getSlice(item.component);

          return Slice ? (
            <Slice
              key={item._uid}
              index={index}
              blok={item}
              heroSideBarHeight={heroSideBarHeight}
            />
          ) : null;
        })}
      </LayoutRest>
    </Layout>
  );
};
