import React, { forwardRef } from 'react';
import { isMobile } from 'react-device-detect';

import {
  changeImageUrl,
  DEFAULT_FOCUS_POINT,
  FOCUS_POINT,
} from '../../helpers/image';

import { Image } from '../image';
import { LanguageSwitcher } from '../language-switcher';
import { Link } from '../link';
import { MaxWidth } from '../max-width';
import { Tag } from '../tag';

/**
 * @typedef {Object} HeroSideBarProps
 * @property {any|null} [image=null]
 * @property {any|null} [imageFocusPoint=null]
 * @property {any|null} [tag=null]
 * @property {any|null} [title=null]
 * @property {any|null} [details=null]
 * @property {any|null} [lastLink=null]
 * @property {any[]|null} [artists=null]
 * @property {boolean} [displayArtistsList=true]
 * @property {any[]} availableLanguages
 * @property {array|null} artists
 */

/**
 * @param {HeroSideBarProps} props
 */
export const HeroSidebar = forwardRef(
  /**
   * @param {HeroSideBarProps} props
   */
  function HeroSidebarWithRef(
    {
      image = null,
      imageFocusPoint = null,
      tag = null,
      title = null,
      details = null,
      lastLink = null,
      artists = null,
      displayArtistsList = true,
      availableLanguages,
    },
    ref,
  ) {
    return (
      <div className="hero-sidebar">
        <div
          className="hero-sidebar__image-container"
          style={{
            '--background-image': image
              ? `url(${changeImageUrl(image.filename, 64)})`
              : null,
            '--background-position':
              FOCUS_POINT[imageFocusPoint] || DEFAULT_FOCUS_POINT,
          }}
        >
          {image ? (
            <Image
              className={'hero-sidebar__image'}
              image={image}
              focusPoint={imageFocusPoint}
              useZoom={isMobile}
              fetchPriority="high"
            />
          ) : null}
        </div>

        <MaxWidth>
          <div ref={ref} className="hero-sidebar__sidebar">
            {tag ? <Tag>{tag}</Tag> : null}

            {title ? <h1 className="hero-sidebar__title">{title}</h1> : null}

            {details ? (
              <div className="hero-sidebar__sidebar__details">{details}</div>
            ) : null}

            {displayArtistsList && artists && artists.length > 0 ? (
              <div className="hero-sidebar__sidebar__artists">
                {artists.map((artist) => {
                  return (
                    <Link key={artist.uuid} page={artist}>
                      {artist?.content?.name}
                    </Link>
                  );
                })}
              </div>
            ) : null}

            {lastLink ? (
              <div className="hero-sidebar__sidebar__bottom">
                {lastLink}
                <LanguageSwitcher availableLanguages={availableLanguages} />
              </div>
            ) : (
              <LanguageSwitcher availableLanguages={availableLanguages} />
            )}
          </div>
        </MaxWidth>
      </div>
    );
  },
);
