import classnames from 'classnames';
import React, { useMemo } from 'react';

import { isLink } from '../../helpers/storyblok/is-link';

import { Link } from '../link';
import { MaxWidth } from '../max-width';

/**
 * @param {Object} props
 * @param {string} props.title
 * @param {string|null} [props.titleLevel=null]
 * @param {any} [props.buttonLink=null]
 * @param {string|null} [props.buttonText=null]
 * @param {'desktop'|'mobile'|null} [props.borderTop=null]
 * @param {'always'|'desktop'|'mobile'|null} [props.borderBottom=null]
 * @param {any} [props.rightPart=null]
 */
export const SectionTitle = ({
  title,
  titleLevel = null,
  buttonLink = null,
  buttonText = null,
  borderTop = null,
  borderBottom = null,
  rightPart = null,
}) => {
  const El = titleLevel || 'p';

  const link = useMemo(() => {
    return buttonText ? (
      isLink(buttonLink) ? (
        <Link className="section-title__link" link={buttonLink}>
          {buttonText}
        </Link>
      ) : (
        <span>{buttonText}</span>
      )
    ) : null;
  }, [buttonText, buttonLink]);

  const hasRightPart = useMemo(() => {
    return !!(rightPart || link);
  }, [rightPart, link]);

  return (
    <MaxWidth
      className={classnames(
        'section-title',
        borderTop ? `section-title--border-top-${borderTop}` : null,
        borderBottom ? `section-title--border-bottom-${borderBottom}` : null,
        {
          'section-title--has-right-part': hasRightPart,
        },
      )}
    >
      <div className="section-title__wrap">
        <El className="section-title__title">{title}</El>

        {hasRightPart ? (
          <div className="section-title__right-part">
            {rightPart}
            {link}
          </div>
        ) : null}
      </div>
    </MaxWidth>
  );
};
