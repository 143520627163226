import classNames from 'classnames';
import React from 'react';

import { ICONS } from '../../components/icons';
import { Link } from '../../components/link';
import { MaxWidth } from '../../components/max-width';

import { render } from '../../helpers/storyblok/rich-text';

export const NotFound = ({ blok, index }) => {
  return (
    <MaxWidth className={classNames("not-found", {
        "not-found--is-first": index === 0
    })}>
      <p className="not-found__sup-title">{blok.supTitle}</p>

      <h1 className="not-found__title">{blok.title}</h1>

      <div className="not-found__description">{render(blok.description)}</div>

      <div className="not-found__link">
        <Link link={blok.backLink}>
          {ICONS.ARROW_RIGHT} <span>{blok.backLinkText}</span>
        </Link>
      </div>
    </MaxWidth>
  );
};
