import React from 'react';

import { render } from '../../helpers/storyblok/rich-text';
import { replaceValuesInRichText } from '../../helpers/string';

import { Link } from '../link';
import { Logo } from '../logo';
import { MaxWidth } from '../max-width';

export const Footer = ({ blok }) => {
  if (!blok) {
    return null;
  }

  return (
    <footer className="footer">
      <hr className="footer__ruler" />

      <MaxWidth>
        <div className="footer__content">
          <div className="footer__content__column">
            <Link href="/" className="footer__content__column__logo">
              <Logo />
            </Link>

            <div className="footer__content__infos">
              {render(blok.content.infos)}
            </div>
          </div>

          {blok.content.columns
            ? blok.content.columns.map((item, index) => {
                return (
                  <div className="footer__content__column" key={index}>
                    <h2
                      dangerouslySetInnerHTML={{
                        __html: item.title,
                      }}
                    />

                    <div className="footer__content__column__description">
                      {render(item.content)}
                    </div>

                    <p className="footer__content__column__sub-text">
                      {item.subText}
                    </p>
                  </div>
                );
              })
            : null}
        </div>
      </MaxWidth>

      <hr className="footer__ruler" />

      <MaxWidth>
        <div className="footer__legal">
          {render(
            replaceValuesInRichText(blok.content.legal, [
              { key: '{YEAR}', value: new Date().getFullYear() },
            ]),
          )}
        </div>
      </MaxWidth>
    </footer>
  );
};
