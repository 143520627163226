import { storyblokEditable } from '@storyblok/react';
import classNames from 'classnames';
import React from 'react';

import {
  FILTER_SECTION_LAYOUT,
  FilterSection,
} from '../../components/filter-section';
import { SectionTitle } from '../../components/section-title';

import { useData } from '../../helpers/data.js';

import { CONTENT_TYPE } from '../../config/content-type.js';

export const AllNews = ({ blok, index }) => {
  const { artists, paginated } = useData();

  const perPage = CONTENT_TYPE.NEWS.limit;

  const overrideList = blok?.overrideList
    ? blok?.overrideList.map((item) => {
        return {
          ...item,
          content: {
            ...item.content,
            artists: (artists || []).filter((artist) => {
              return item.content.artists.includes(artist.uuid);
            }),
          },
        };
      })
    : [];

  return (
    <div
      className={classNames('all-news', {
        'all-news--first': index === 0,
      })}
      {...storyblokEditable(blok)}
    >
      <SectionTitle
        title={blok.title}
        titleLevel={blok.titleLevel || 'h1'}
        buttonText={blok.buttonText}
        buttonLink={blok.buttonLink}
      />

      <FilterSection
        contentType={CONTENT_TYPE.NEWS}
        initialItems={[
          ...overrideList,
          ...(paginated?.news?.stories || []).filter((item) => {
            return !overrideList.find((overrideItem) => {
              return item.uuid === overrideItem.uuid;
            });
          }),
        ].slice(0, perPage)}
        total={paginated?.news?.total || 0}
        perPage={perPage || 1}
        filterLabel={blok.filterLabel}
        filterItems={artists || []}
        links={blok.links}
        tokens={blok}
        moreButtonText={blok.moreButtonText}
        lastGridCount={3}
        lastGridCountMobile={1}
        layout={FILTER_SECTION_LAYOUT.DEFAULT}
        isFirst={index === 0}
      />
    </div>
  );
};
