'use client';

import cookies from 'js-cookie';
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';

import { LANGUAGE_KEY } from '../components/language-switcher';

import { DEFAULT_LANGUAGE, LANGUAGES } from '../helpers/translation';

const initialStore = {
  language: DEFAULT_LANGUAGE,
};

const Store = createContext([{}, {}]);

export const useStore = () => {
  return useContext(Store);
};

export const StoreProvider = ({ children }) => {
  const [store, setStore] = useState(initialStore);

  const set = useCallback(
    (name, value) => {
      setStore((state) => {
        const updated = Object.assign({}, state);

        updated[name] = value;

        return updated;
      });

      switch (name) {
        case LANGUAGE_KEY:
          cookies.set(LANGUAGE_KEY, value?.shortName);

          break;
      }
    },
    [setStore],
  );

  const actions = {
    set,
  };

  useEffect(() => {
    if (
      cookies.get(LANGUAGE_KEY) &&
      store.language?.shortName !== cookies.get(LANGUAGE_KEY)
    ) {
      const cookieLang = LANGUAGES.find((lang) => {
        return lang?.shortName === cookies.get(LANGUAGE_KEY);
      });

      if (cookieLang) {
        set(LANGUAGE_KEY, cookieLang);
      }
    }
  }, []);

  return <Store.Provider value={[store, actions]}>{children}</Store.Provider>;
};
