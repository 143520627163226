import React from 'react';

import { CONFIG } from '../../config/index.js';

export const Logo = ({ color = 'black' }) => {
  return (
    <svg
      aria-label={CONFIG.title}
      width="260"
      height="46"
      viewBox="0 0 260 46"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M148.806 8.62749C151.187 8.71895 153.189 6.89883 153.279 4.56214C153.369 2.22545 151.512 0.257053 149.131 0.165596C146.75 0.0741401 144.748 1.89426 144.658 4.23095C144.568 6.56764 146.425 8.53604 148.806 8.62749Z"
        fill={color}
      />
      <path
        d="M166.427 1.74609H159.981C159.933 1.74609 159.895 1.78464 159.895 1.83218V38.1789C159.895 38.2264 159.933 38.2649 159.981 38.2649H166.427C166.475 38.2649 166.513 38.2264 166.513 38.1789V1.83218C166.513 1.78464 166.475 1.74609 166.427 1.74609Z"
        fill={color}
      />
      <path
        d="M180.457 1.74609H174.17C174.123 1.74609 174.084 1.78464 174.084 1.83218V38.1789C174.084 38.2264 174.123 38.2649 174.17 38.2649H180.457C180.505 38.2649 180.543 38.2264 180.543 38.1789V1.83218C180.543 1.78464 180.505 1.74609 180.457 1.74609Z"
        fill={color}
      />
      <path
        d="M29.6512 26.3815C29.4217 27.3465 29.1496 28.1298 28.835 28.7313C25.6063 34.8741 20.5114 38.0422 13.5503 38.2356C12.0645 38.276 7.6073 38.3228 0.178544 38.3759C0.136265 38.3759 0.0957095 38.3591 0.0658133 38.3292C0.0359171 38.2993 0.0191291 38.2588 0.0191291 38.2165L0 2.3672C4.51132e-06 2.34641 0.00407432 2.32583 0.0119678 2.30667C0.0198613 2.2875 0.0314148 2.27013 0.0459642 2.25558C0.0605137 2.24103 0.0777635 2.22959 0.0966961 2.22193C0.115629 2.21427 0.135865 2.21055 0.156224 2.21097C3.92905 2.30024 7.70295 2.32256 11.4779 2.27792C12.8956 2.26092 14.307 2.34594 15.712 2.53299C18.8323 2.94747 21.5763 4.0655 23.9442 5.88709C25.5702 7.1369 26.9582 8.7268 28.1081 10.6568C30.8819 15.316 31.476 20.4651 29.8904 26.1041C29.8755 26.1636 29.8521 26.2072 29.8202 26.2349C29.799 26.2519 29.7756 26.2657 29.7501 26.2763C29.7246 26.2871 29.7023 26.3023 29.6851 26.3206C29.6679 26.339 29.6563 26.3598 29.6512 26.3815ZM21.2341 28.2244C22.6476 26.4878 23.5127 24.5748 23.8294 22.4854C24.1397 20.4406 24.0005 18.415 23.4117 16.4085C22.8655 14.5465 21.8941 12.929 20.4976 11.5559C19.4455 10.5186 18.2754 9.73962 16.9873 9.21886C15.6588 8.67898 14.2475 8.41116 12.7532 8.41541C10.8402 8.42179 8.89218 8.42498 6.90905 8.42498C6.8524 8.42498 6.79807 8.44715 6.75801 8.48661C6.71794 8.52607 6.69544 8.5796 6.69544 8.63541L6.77196 32.0184C6.77275 32.0619 6.79028 32.1036 6.82104 32.1349C6.85179 32.1663 6.8935 32.185 6.93776 32.1874C8.77847 32.3107 10.6181 32.3213 12.4567 32.2193C14.4951 32.1066 16.0924 31.7974 17.2487 31.2915C18.7897 30.6156 20.1182 29.5932 21.2341 28.2244Z"
        fill={color}
      />
      <path
        d="M41.3283 27.5721C41.5536 28.9983 42.102 30.2237 42.9734 31.2482C43.8237 32.2451 44.9024 32.9348 46.2096 33.3174C47.9887 33.8361 49.7688 33.6618 51.55 32.7945C52.4448 32.3567 53.3939 31.7073 54.3972 30.8465C54.431 30.8173 54.4743 30.8018 54.5181 30.803C54.562 30.8041 54.6031 30.822 54.6331 30.8529L57.9776 34.309C58.0095 34.3414 58.029 34.3842 58.0331 34.4306C58.0371 34.4769 58.0255 34.5239 57.9999 34.5641C57.6513 35.0997 57.1348 35.6119 56.4504 36.1008C54.5226 37.4782 52.4672 38.3337 50.2842 38.6674C48.1183 38.999 46.0087 38.8917 43.9554 38.3454C41.6599 37.7354 39.7012 36.5089 38.0794 34.6661C36.3237 32.6766 35.3119 30.2535 35.0441 27.3967C34.8124 24.9099 35.144 22.5792 36.0389 20.4048C36.8976 18.3196 38.1379 16.6224 39.7596 15.3131C41.2709 14.0909 43.0053 13.314 44.963 12.9824C46.8271 12.6678 48.6635 12.7901 50.4723 13.3491C53.6968 14.3438 56.0508 16.5182 57.5345 19.8723C58.5377 22.1403 58.9043 24.608 58.6344 27.2756C58.6288 27.3246 58.6055 27.3697 58.5689 27.4021C58.5324 27.4346 58.4852 27.452 58.4367 27.4509L41.4654 27.4127C41.4456 27.4124 41.4261 27.4165 41.4081 27.4247C41.3901 27.4329 41.374 27.445 41.361 27.4601C41.348 27.4752 41.3383 27.4931 41.3327 27.5124C41.327 27.5317 41.3255 27.5521 41.3283 27.5721ZM42.8874 20.0732C42.2901 20.7321 41.8884 21.5026 41.6822 22.3847C41.676 22.4117 41.6759 22.4397 41.682 22.4667C41.688 22.4937 41.7001 22.519 41.7172 22.5407C41.7343 22.5624 41.7561 22.58 41.781 22.5922C41.8058 22.6044 41.8331 22.6109 41.8607 22.6111L51.8688 22.592C51.8886 22.5917 51.9081 22.5873 51.926 22.579C51.9439 22.5706 51.9597 22.5587 51.9725 22.5438C51.9852 22.529 51.9945 22.5116 51.9997 22.493C52.0049 22.4743 52.006 22.4548 52.0027 22.4357C51.8114 21.2943 51.3417 20.3474 50.5935 19.595C49.8559 18.8553 48.9196 18.394 47.7846 18.2112C45.9184 17.9094 44.286 18.5301 42.8874 20.0732Z"
        fill={color}
      />
      <path
        d="M70.9176 15.5593C73.5087 13.3211 76.5375 12.4315 80.0043 12.8907C80.0479 12.897 80.0875 12.9192 80.1153 12.9529C80.143 12.9866 80.1569 13.0292 80.1541 13.0724C80.0521 15.0449 79.8321 17.0046 79.4942 18.9516C79.4907 18.9722 79.4831 18.992 79.472 19.0096C79.4609 19.0273 79.4464 19.0425 79.4295 19.0544C79.4125 19.0663 79.3934 19.0746 79.3733 19.0788C79.3532 19.0831 79.3325 19.0832 79.3124 19.0792C76.2793 18.4713 73.7085 19.3417 71.5999 21.6904C71.2088 22.1261 71.0122 22.6256 71.0101 23.1889L70.9399 38.0783C70.9399 38.1214 70.9225 38.1628 70.8914 38.1933C70.8603 38.2237 70.8181 38.2409 70.7741 38.2409L64.7004 38.26C64.6795 38.26 64.6588 38.256 64.6394 38.2481C64.6201 38.2403 64.6025 38.2288 64.5877 38.2143C64.5729 38.1997 64.5612 38.1825 64.5532 38.1636C64.5451 38.1446 64.541 38.1243 64.541 38.1038V13.5889C64.541 13.5475 64.5578 13.5077 64.5877 13.4784C64.6176 13.4491 64.6582 13.4327 64.7004 13.4327L70.3501 13.4359C70.4207 13.4357 70.4905 13.4516 70.554 13.4824C70.6175 13.5132 70.6731 13.558 70.7165 13.6134C70.7599 13.6689 70.79 13.7334 70.8044 13.8021C70.8188 13.8708 70.8172 13.9418 70.7997 14.0098C70.6594 14.5326 70.6456 15.0332 70.7582 15.5114C70.7619 15.5275 70.7699 15.5422 70.7812 15.5542C70.7926 15.5662 70.8069 15.575 70.823 15.5798C70.839 15.5846 70.8561 15.5853 70.8727 15.5817C70.8893 15.5781 70.9048 15.5704 70.9176 15.5593Z"
        fill={color}
      />
      <path
        d="M108.899 26.3698C108.766 29.8384 107.245 33.1114 104.67 35.4688C102.095 37.8261 98.6771 39.0748 95.1688 38.94C93.4317 38.8733 91.7245 38.4689 90.1449 37.75C88.5652 37.0311 87.144 36.0117 85.9623 34.7501C84.7806 33.4885 83.8616 32.0093 83.2578 30.397C82.654 28.7847 82.3772 27.0709 82.4431 25.3535C82.5764 21.8849 84.0978 18.6119 86.6728 16.2545C89.2478 13.8972 92.6653 12.6485 96.1736 12.7833C97.9108 12.8501 99.6179 13.2544 101.198 13.9733C102.777 14.6922 104.198 15.7116 105.38 16.9732C106.562 18.2348 107.481 19.714 108.085 21.3263C108.688 22.9386 108.965 24.6524 108.899 26.3698ZM95.6872 32.8632C97.4477 32.8632 99.1361 32.1272 100.381 30.8171C101.626 29.5071 102.325 27.7303 102.325 25.8776C102.325 24.9602 102.154 24.0519 101.82 23.2043C101.486 22.3568 100.997 21.5867 100.381 20.938C99.7646 20.2894 99.0328 19.7748 98.2274 19.4238C97.4221 19.0727 96.5589 18.892 95.6872 18.892C94.8154 18.892 93.9522 19.0727 93.1469 19.4238C92.3415 19.7748 91.6098 20.2894 90.9934 20.938C90.377 21.5867 89.888 22.3568 89.5544 23.2043C89.2208 24.0519 89.0491 24.9602 89.0491 25.8776C89.0491 27.7303 89.7485 29.5071 90.9934 30.8171C92.2382 32.1272 93.9266 32.8632 95.6872 32.8632Z"
        fill={color}
      />
      <path
        d="M212.567 25.0084C212.791 28.4723 211.615 31.8843 209.298 34.4937C206.981 37.1031 203.712 38.6963 200.211 38.9227C198.477 39.0348 196.739 38.808 195.095 38.2552C193.451 37.7024 191.934 36.8345 190.629 35.7009C189.325 34.5674 188.26 33.1905 187.494 31.6488C186.728 30.1071 186.277 28.4308 186.166 26.7156C185.942 23.2517 187.118 19.8398 189.435 17.2303C191.752 14.6209 195.021 13.0277 198.522 12.8013C200.255 12.6892 201.994 12.916 203.638 13.4688C205.282 14.0216 206.799 14.8895 208.103 16.0231C209.408 17.1566 210.473 18.5335 211.239 20.0752C212.005 21.6169 212.456 23.2932 212.567 25.0084ZM199.321 32.8506C201.088 32.863 202.788 32.1398 204.047 30.8403C205.306 29.5408 206.02 27.7714 206.033 25.9213C206.039 25.0052 205.873 24.0969 205.544 23.2482C205.215 22.3995 204.73 21.6271 204.116 20.975C203.502 20.3229 202.771 19.8039 201.965 19.4477C201.159 19.0915 200.293 18.905 199.418 18.8989C197.651 18.8866 195.951 19.6097 194.692 20.9092C193.434 22.2087 192.719 23.9781 192.706 25.8282C192.7 26.7443 192.866 27.6526 193.195 28.5013C193.524 29.35 194.009 30.1225 194.623 30.7746C195.238 31.4266 195.969 31.9456 196.775 32.3018C197.581 32.658 198.446 32.8445 199.321 32.8506Z"
        fill={color}
      />
      <path
        d="M224.804 15.0922C225.01 15.0136 225.28 14.8626 225.614 14.6395C227.791 13.1835 230.189 12.6266 232.81 12.9688C237.542 13.5873 240.653 16.1422 242.145 20.6335C242.528 21.7834 242.696 23.5901 242.649 26.0536C242.577 29.9327 242.598 33.9074 242.713 37.9779C242.714 38.0135 242.707 38.049 242.694 38.0823C242.681 38.1155 242.661 38.1458 242.635 38.1713C242.609 38.1969 242.579 38.2172 242.545 38.231C242.512 38.2449 242.475 38.252 242.439 38.252L236.346 38.3062C236.299 38.3062 236.253 38.2881 236.22 38.2558C236.186 38.2235 236.167 38.1797 236.167 38.1341C236.167 33.8533 236.167 29.6139 236.167 25.4159C236.167 24.8378 236.115 24.1799 236.011 23.4424C235.899 22.6602 235.721 22.0289 235.479 21.5485C234.992 20.5856 234.274 19.8747 233.323 19.4155C232.52 19.0266 231.573 18.8661 230.483 18.9341C228.215 19.0744 226.334 20.0819 224.839 21.9566C224.742 22.0784 224.689 22.229 224.69 22.3838V38.0863C224.69 38.1294 224.672 38.1707 224.642 38.2012C224.611 38.2317 224.57 38.2489 224.527 38.2489L218.37 38.268C218.35 38.268 218.329 38.264 218.31 38.2561C218.292 38.2482 218.274 38.2367 218.26 38.2222C218.245 38.2077 218.234 38.1905 218.226 38.1715C218.218 38.1526 218.214 38.1323 218.214 38.1118L218.176 13.5586C218.176 13.5163 218.193 13.4758 218.222 13.4459C218.252 13.416 218.293 13.3992 218.335 13.3992L224.291 13.4566C224.337 13.4563 224.382 13.4718 224.418 13.5004C224.453 13.5289 224.477 13.5687 224.485 13.6128C224.556 13.9699 224.523 14.3259 224.387 14.6809C224.217 15.1315 224.356 15.2686 224.804 15.0922Z"
        fill={color}
      />
      <path
        d="M113.925 23.2342C113.935 20.1309 113.955 16.9139 113.982 13.5832C113.982 13.5561 113.987 13.5293 113.998 13.5044C114.008 13.4794 114.023 13.4568 114.041 13.4378C114.06 13.4188 114.082 13.4038 114.106 13.3938C114.131 13.3837 114.157 13.3787 114.183 13.3792L120.333 13.4589C120.355 13.4593 120.375 13.4639 120.395 13.4725C120.414 13.4811 120.432 13.4935 120.446 13.5089C120.461 13.5244 120.472 13.5425 120.48 13.5624C120.487 13.5823 120.49 13.6035 120.49 13.6247C120.377 18.2328 120.398 22.8389 120.553 27.4428C120.587 28.4481 120.698 29.2016 120.885 29.7033C121.174 30.4706 121.619 31.1146 122.221 31.6354C123.018 32.3219 123.984 32.7109 125.119 32.8023C126.207 32.8895 127.275 32.679 128.323 32.171C129.439 31.6311 130.377 30.9 131.135 29.9775C131.329 29.7427 131.435 29.4462 131.435 29.1389L131.454 13.6278C131.454 13.5846 131.471 13.543 131.501 13.5116C131.531 13.4802 131.573 13.4613 131.617 13.4589C133.604 13.3356 135.668 13.3366 137.808 13.462C137.852 13.4645 137.894 13.4837 137.924 13.5157C137.954 13.5478 137.971 13.5902 137.971 13.6342L138.028 38.0566C138.029 38.0778 138.025 38.0989 138.017 38.1186C138.009 38.1383 137.998 38.1563 137.983 38.1714C137.968 38.1866 137.95 38.1986 137.931 38.2068C137.911 38.215 137.89 38.2192 137.869 38.2192L131.913 38.2384C131.878 38.2384 131.844 38.2221 131.817 38.1926C131.791 38.1631 131.774 38.1225 131.77 38.079L131.62 36.6538C131.609 36.5411 131.552 36.5231 131.448 36.5996C128.789 38.5529 125.975 39.2554 123.005 38.7071C120.822 38.3053 118.946 37.3743 117.378 35.9141C115.431 34.101 114.324 31.714 114.059 28.7532C113.961 27.6564 113.916 25.8167 113.925 23.2342ZM114.894 27.7393C114.896 27.9858 114.942 28.2218 115.031 28.4471C115.099 28.6214 115.123 28.6171 115.101 28.4343L114.881 26.4512C114.869 26.3343 114.858 26.3343 114.849 26.4512C114.832 26.6553 114.838 26.871 114.865 27.0984C114.882 27.2493 114.892 27.463 114.894 27.7393Z"
        fill={color}
      />
      <path
        d="M152.243 38.1897C152.243 38.2006 152.241 38.2113 152.236 38.2214C152.232 38.2314 152.226 38.2406 152.218 38.2483C152.211 38.256 152.202 38.2621 152.192 38.2663C152.181 38.2704 152.171 38.2726 152.16 38.2726H145.866C145.855 38.2726 145.844 38.2704 145.834 38.2663C145.824 38.2621 145.815 38.256 145.807 38.2483C145.8 38.2406 145.794 38.2314 145.79 38.2214C145.785 38.2113 145.783 38.2006 145.783 38.1897V13.4931C145.783 13.4711 145.792 13.45 145.807 13.4344C145.823 13.4189 145.844 13.4102 145.866 13.4102H152.16C152.182 13.4102 152.203 13.4189 152.218 13.4344C152.234 13.45 152.243 13.4711 152.243 13.4931V38.1897ZM151.806 35.6865C151.862 35.6857 151.916 35.5905 151.966 35.4061C152.015 35.2218 152.058 34.9521 152.093 34.6122C152.128 34.2724 152.154 33.8692 152.169 33.4257C152.184 32.9821 152.189 32.5069 152.182 32.0271C152.175 31.5473 152.158 31.0724 152.13 30.6294C152.102 30.1865 152.065 29.7841 152.021 29.4454C151.977 29.1067 151.926 28.8383 151.871 28.6554C151.817 28.4725 151.76 28.3788 151.704 28.3796C151.648 28.3804 151.594 28.4756 151.544 28.66C151.495 28.8443 151.452 29.114 151.417 29.4539C151.382 29.7937 151.356 30.1969 151.341 30.6404C151.325 31.084 151.321 31.5592 151.328 32.039C151.334 32.5188 151.352 32.9937 151.38 33.4367C151.407 33.8796 151.445 34.282 151.489 34.6207C151.533 34.9594 151.584 35.2278 151.639 35.4107C151.693 35.5936 151.75 35.6873 151.806 35.6865Z"
        fill={color}
      />
      <path
        d="M114.869 27.1021C114.842 26.8747 114.836 26.6589 114.853 26.4549C114.862 26.338 114.873 26.338 114.885 26.4549L115.105 28.438C115.127 28.6208 115.103 28.625 115.035 28.4507C114.946 28.2254 114.9 27.9895 114.898 27.7429C114.896 27.4666 114.886 27.253 114.869 27.1021Z"
        fill={color}
      />
      <path
        d="M151.326 32.0365C151.354 34.0542 151.568 35.6872 151.804 35.6839C152.04 35.6806 152.208 34.0423 152.18 32.0245C152.152 30.0068 151.938 28.3738 151.702 28.3771C151.466 28.3804 151.297 30.0187 151.326 32.0365Z"
        fill={color}
      />
      <path
        d="M252.63 30.9101C252.653 30.8202 252.704 30.7406 252.776 30.684C252.848 30.6273 252.935 30.5969 253.025 30.5977L259.526 30.6646C259.545 30.6647 259.562 30.6693 259.578 30.678C259.594 30.6868 259.608 30.6994 259.618 30.7148C259.628 30.7301 259.634 30.7478 259.636 30.7663C259.638 30.7848 259.635 30.8035 259.628 30.8209L253.771 45.2352C253.732 45.3329 253.666 45.4165 253.583 45.4751C253.5 45.5336 253.404 45.5645 253.306 45.5636L249.005 45.5062C248.986 45.5061 248.968 45.5015 248.951 45.4927C248.934 45.4839 248.919 45.4712 248.908 45.4555C248.897 45.4399 248.889 45.4217 248.885 45.4023C248.881 45.3829 248.882 45.3628 248.887 45.3436L252.63 30.9101Z"
        fill={color}
      />
    </svg>
  );
};
