import dynamic from 'next/dynamic';

import { Loading } from '../components/loading';

import { AllEditions } from './all-editions';
import { AllExhibitions } from './all-exhibitions';
import { AllFairs } from './all-fairs';
import { AllNews } from './all-news';
import { HeroLeft } from './hero-left';
import { ListArtists } from './list-artists';
import { NotFound } from './not-found';
import { Text } from './text';

const SLICE = {
  'hero-left': HeroLeft,
  'list-artists': ListArtists,
  'not-found': NotFound,
  text: Text,
  'all-exhibitions': AllExhibitions,
  'all-news': AllNews,
  'all-fairs': AllFairs,
  'all-editions': AllEditions,
  'alphabetical-artists': dynamic(
    () => {
      return import('./alphabetical-artists').then(
        (module) => module.AlphabeticalArtists,
      );
    },
    { loading: Loading },
  ),
  'list-exhibitions': dynamic(
    () => {
      return import('./list-exhibitions').then(
        (module) => module.ListExhibitions,
      );
    },
    { loading: Loading },
  ),
  'list-news': dynamic(
    () => {
      return import('./list-news').then((module) => module.ListNews);
    },
    { loading: Loading },
  ),
  'artist-exhibitions': dynamic(
    () => {
      return import('./artist-exhibitions').then(
        (module) => module.ArtistExhibitions,
      );
    },
    { loading: Loading },
  ),
  'artist-news': dynamic(
    () => {
      return import('./artist-news').then((module) => module.ArtistNews);
    },
    { loading: Loading },
  ),
  'artist-editions': dynamic(
    () => {
      return import('./artist-editions').then(
        (module) => module.ArtistEditions,
      );
    },
    { loading: Loading },
  ),
  'right-content': dynamic(
    () => {
      return import('./right-content').then((module) => module.RightContent);
    },
    { loading: Loading },
  ),
  'image-text': dynamic(
    () => {
      return import('./image-text').then((module) => module.ImageText);
    },
    { loading: Loading },
  ),
  'infinite-slider': dynamic(
    () => {
      return import('./infinite-slider').then(
        (module) => module.InfiniteSlider,
      );
    },
    { loading: Loading },
  ),
  'video-section': dynamic(
    () => {
      return import('./video-section').then((module) => module.VideoSection);
    },
    { loading: Loading },
  ),
  'title-section': dynamic(
    () => {
      return import('./title-section').then((module) => module.TitleSection);
    },
    { loading: Loading },
  ),
};

export const getSlice = (name) => {
  return SLICE[name] || null;
};
