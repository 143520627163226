import classnames from 'classnames';
import compareFunc from 'compare-func';
import { useSearchParams } from 'next/navigation';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroller';

import { Card, CARD_LAYOUT } from '../../components/card';
import { Filter } from '../../components/filter';
import { Grid } from '../../components/grid';
import { SelectInput } from '../../components/inputs/select';
import { Link } from '../../components/link';
import { Loading } from '../../components/loading';
import { MaxWidth } from '../../components/max-width';
import { Pagination } from '../../components/pagination';
import { WideCard } from '../../components/wide-card';

import { useData } from '../../helpers/data';
import {
  getBlokTempolarity,
  TEMPOLARITY,
  TEMPORALITY_TOKEN,
} from '../../helpers/date';
import { fetchStoriesPage } from '../../helpers/storyblok/graphql';
import { render } from '../../helpers/storyblok/rich-text';
import { stringToSlug } from '../../helpers/string';

import { CONTENT_TYPE } from '../../config/content-type.js';

export const FILTER_SECTION_LAYOUT = {
  DEFAULT: 'default',
  WITH_TEMPORALITY: 'with-temporality',
  NO_PREVIEW: 'no-preview',
};

const SECTION_ID = {
  CURRENT_UPCOMING: 'current-upcoming',
  PAST: 'past',
};

/**
 * @param {Object} props
 * @param {import('../../config/content-type.js').ContentType} props.contentType
 * @param {array} props.initialItems
 * @param {number} props.total
 * @param {number} props.perPage
 * @param {string} props.filterLabel
 * @param {array} [props.filterItems=[]]
 * @param {array} props.links
 * @param {array} props.tokens
 * @param {string} props.moreButtonText
 * @param {string} [props.layout=FILTER_SECTION_LAYOUT.DEFAULT]
 * @param {number} [props.lastGridCount=1]
 * @param {number} [props.lastGridCountMobile]
 * @param {boolean} [props.displayDates=true]
 * @param {boolean|false} [props.isFirst=false]
 */
export const FilterSection = ({
  contentType,
  initialItems,
  total,
  perPage,
  filterLabel,
  filterItems = [],
  links,
  tokens,
  moreButtonText,
  layout = FILTER_SECTION_LAYOUT.DEFAULT,
  lastGridCount = 1,
  lastGridCountMobile,
  displayDates = true,
  isFirst = false,
}) => {
  const [infiniteScroll, setInfiniteScroll] = useState(false);

  const searchParams = useSearchParams();

  const page = parseInt(searchParams.get('page') || '', 10) || 1;
  const lastPage = Math.ceil(total / perPage);

  useEffect(() => {
    setInfiniteScroll(page === 1);
  }, [page]);

  const { locale } = useData();

  const [filterIsOpen, setFilterIsOpen] = useState(false);

  const [filter, setFilter] = useState(
    CONTENT_TYPE.ARTIST.filter?.searchParamName
      ? searchParams.get(CONTENT_TYPE.ARTIST.filter?.searchParamName) || null
      : null,
  );

  const [hasMore, setHasMore] = useState(page < lastPage);
  const [items, setItems] = useState(initialItems);

  const [fetching, setFetching] = useState(false);

  const loadMore = useCallback(
    async (page, props) => {
      if (fetching || !infiniteScroll) {
        return;
      }

      const currentFilter =
        typeof props?.filter !== 'undefined' ? props?.filter : filter;

      setFetching(true);
      setHasMore(false);

      if (page === 1) {
        setItems([]);
      }

      const artist = currentFilter
        ? filterItems.find((filterItem) => {
            return (
              stringToSlug(filterItem.content?.name || '') === currentFilter
            );
          })
        : null;

      const data = (
        await fetchStoriesPage({
          startsWith: `${locale.shortCode}/`,
          sortBy: 'content.startAt:desc',
          contentType,
          page,
          perPage,
          filterQueryV2: artist?.uuid
            ? {
                artists: {
                  in_array: [artist.uuid],
                },
              }
            : null,
          unstableCache: false,
        })
      ).stories;

      setItems(
        page === 1
          ? data
          : (currentItems) => {
              return [...currentItems.slice(), ...data.slice()];
            },
      );

      setFetching(false);
      setHasMore(data.length === perPage);
    },
    [
      fetching,
      infiniteScroll,
      filter,
      filterItems,
      locale.shortCode,
      contentType,
      perPage,
    ],
  );

  const handleFilter = useCallback(
    (value) => {
      if (fetching) {
        return;
      }

      setFilter(value);
      loadMore(1, {
        filter: value,
      });
    },
    [fetching, setFilter, loadMore],
  );

  const currentItems = useMemo(() => {
    return layout === FILTER_SECTION_LAYOUT.DEFAULT_LAYOUT
      ? []
      : items.filter((item) => {
          return getBlokTempolarity(item) === TEMPOLARITY.CURRENT;
        });
  }, [items, layout]);

  const upcomingItems = useMemo(() => {
    return layout === FILTER_SECTION_LAYOUT.DEFAULT_LAYOUT
      ? []
      : items.filter((item) => {
          return getBlokTempolarity(item) === TEMPOLARITY.UPCOMING;
        });
  }, [items, layout]);

  const pastItems = useMemo(() => {
    return layout === FILTER_SECTION_LAYOUT.DEFAULT_LAYOUT
      ? []
      : items.filter((item) => {
          return getBlokTempolarity(item) === TEMPOLARITY.PAST;
        });
  }, [items, layout]);

  const currentUpcoming = [...upcomingItems, ...currentItems];
  // const currentUpcomingOdd = currentUpcoming.length % 2 === 0;
  // const itemsOdd = items.length % 2 === 0;

  // const first =
  //   layout === FILTER_SECTION_LAYOUT.WITH_TEMPORALITY
  //     ? currentUpcomingOdd
  //       ? []
  //       : currentUpcoming.slice(0, 1)
  //     : items.length > 0 && (!itemsOdd || items.length >= 3)
  //       ? [items[0]]
  //       : [];

  const first =
    layout === FILTER_SECTION_LAYOUT.WITH_TEMPORALITY
      ? currentUpcoming.slice(0, 3)
      : items.slice(0, 1);

  // const second =
  //   layout === FILTER_SECTION_LAYOUT.WITH_TEMPORALITY
  //     ? currentUpcoming.slice(currentUpcomingOdd ? 0 : 1)
  //     : items.length > 1
  //       ? itemsOdd && items.length <= 3
  //         ? items.slice(0, 2)
  //         : items.slice(1, 3)
  //       : [];

  return (
    <div
      className={classnames(
        'filter-section',
        `filter-section--layout-${layout}`,
        {
          'filter-section--filter-is-open': filterIsOpen,
        },
      )}
    >
      <InfiniteScroll
        key={filter}
        pageStart={1}
        initialLoad={false}
        loadMore={loadMore}
        hasMore={hasMore}
        threshold={700}
      >
        <MaxWidth>
          {filterLabel && filterItems?.length > 0 ? (
            <Filter
              links={
                links.length > 0
                  ? links.map((item) => {
                      let current = false;

                      if (item?.link?.url.startsWith('#')) {
                        const id = item?.link?.url.slice(1);

                        switch (id) {
                          case SECTION_ID.CURRENT_UPCOMING:
                            if (!currentUpcoming || currentUpcoming.length <= 0)
                              return null;
                            current = true;
                            break;

                          case SECTION_ID.PAST:
                            if (!pastItems || pastItems.length <= 0)
                              return null;
                            if (!currentUpcoming || currentUpcoming.length <= 0)
                              current = true;
                            break;
                        }
                      }

                      return (
                        <li
                          key={item._uid}
                          className={classnames({
                            'filter__link--current': current,
                          })}
                        >
                          <Link link={item.link}>{item.title}</Link>
                        </li>
                      );
                    })
                  : null
              }
              select={
                infiniteScroll ? (
                  <SelectInput
                    setFilterIsOpen={setFilterIsOpen}
                    options={[
                      ...(filter === null
                        ? []
                        : [
                            {
                              label: 'All',
                              value: null,
                            },
                          ]),
                      ...filterItems
                        .map((item) => {
                          return {
                            sortValue:
                              item.content.sortingName || item.content.name,
                            ...item,
                          };
                        })
                        .sort(compareFunc('sortValue'))
                        .map((filterItem) => {
                          return {
                            label: filterItem.content.name,
                            value: stringToSlug(filterItem.content?.name || ''),
                          };
                        }),
                    ]}
                    defaultLabel={filterLabel}
                    setValue={handleFilter}
                    current={filter}
                  />
                ) : null
              }
            />
          ) : null}

          <div className="filter-section__result">
            {items.length === 0 && !fetching ? (
              <p className="filter-section__no-result">No result</p>
            ) : (
              <>
                {layout !== FILTER_SECTION_LAYOUT.NO_PREVIEW &&
                (layout === FILTER_SECTION_LAYOUT.DEFAULT ||
                  (FILTER_SECTION_LAYOUT.WITH_TEMPORALITY &&
                    currentUpcoming &&
                    currentUpcoming.length > 0)) ? (
                  <div
                    className="filter-section__current-upcoming"
                    id={SECTION_ID.CURRENT_UPCOMING}
                  >
                    {first.length > 0 ? (
                      <Grid columns={1}>
                        {first.map((item, index) => {
                          const tag = render(
                            tokens[
                              `token${
                                TEMPORALITY_TOKEN[getBlokTempolarity(item)]
                              }Item`
                            ],
                          );

                          return (
                            <Card.Preview
                              key={item?.uuid}
                              blok={item}
                              tag={tag || item.content?.category || ''}
                              link={<Link page={item}>{moreButtonText}</Link>}
                              layout={CARD_LAYOUT.FULL}
                              displayDates={displayDates}
                              lazyImage={!isFirst}
                              reverse={index % 2 === 1}
                            />
                          );
                        })}
                      </Grid>
                    ) : null}

                    {/* {second.length > 0 ? (
                      <Grid columns={2}>
                        {second.map((item) => {
                          const tag = render(
                            tokens[
                              `token${
                                TEMPORALITY_TOKEN[getBlokTempolarity(item)]
                              }Item`
                            ],
                          );

                          return (
                            <Card.Preview
                              key={item?.uuid}
                              blok={item}
                              tag={tag || item.content?.category || ''}
                              link={moreButtonText}
                              layout={CARD_LAYOUT.HALF}
                              displayDates={displayDates}
                              lazyImage={!isFirst}
                            />
                          );
                        })}
                      </Grid>
                    ) : null} */}
                  </div>
                ) : null}

                {(layout === FILTER_SECTION_LAYOUT.DEFAULT &&
                  items.length > 1) ||
                (FILTER_SECTION_LAYOUT.WITH_TEMPORALITY &&
                  pastItems &&
                  pastItems.length > 0) ? (
                  <Grid
                    columns={lastGridCount}
                    mobileColumns={lastGridCountMobile}
                    className="filter-section__past-items"
                    id={SECTION_ID.PAST}
                  >
                    {(layout === FILTER_SECTION_LAYOUT.WITH_TEMPORALITY
                      ? pastItems
                      : layout === FILTER_SECTION_LAYOUT.NO_PREVIEW
                        ? items
                        : items.slice(1)
                    ).map((item) => {
                      const tag = render(
                        tokens[
                          `token${
                            TEMPORALITY_TOKEN[getBlokTempolarity(item)]
                          }Item`
                        ],
                      );

                      return lastGridCount > 1 ? (
                        <Card.Preview
                          key={item?.uuid}
                          blok={item}
                          tag={tag || item.content?.category || ''}
                          link={moreButtonText}
                          layout={CARD_LAYOUT.THIRD}
                          displayDates={displayDates}
                        />
                      ) : (
                        <WideCard
                          key={item?.uuid}
                          blok={{
                            ...item.content,
                            supTitle: tag || item.content?.category || '',
                          }}
                          item={item}
                          content={{ moreButtonText }}
                          displayDates={displayDates}
                        />
                      );
                    })}
                  </Grid>
                ) : null}
              </>
            )}

            {fetching ? (
              <Loading />
            ) : !infiniteScroll || hasMore ? (
              <Pagination page={page} lastPage={lastPage} />
            ) : null}
          </div>
        </MaxWidth>
      </InfiniteScroll>
    </div>
  );
};
